import React from "react";
import { Drawer, Button, Menu } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import mainActions from "../../redux/main/actions";
import logo from "../../images/logo.png";
import homeIcon from "../../images/home.png";
import favoriteIcon from "../../images/edit.png";
// import cancelResrvationIcon from "../../images/document-circle-wrong.png";
import personalInfromationIcon from "../../images/user.png";
import orderHistoryIcon from "../../images/time-past.png";
import "./index.css";

const MenuDrawer = () => {
  const dispatch = useDispatch();
  const { setOpenMenuDrawer } = mainActions;
  const { openMenuDrawer } = useSelector((state) => state.mainReducer);

  const closeMenuDrawer = () => {
    dispatch(setOpenMenuDrawer(false));
  };

  const items = [
    {
      key: "1",
      label: (
        <Link to="/">
          <p className="menu-drawer-menu-text">Начало</p>
        </Link>
      ),
      icon: (
        <img src={homeIcon} className="menu-drawer-menu-image" alt="homeIcon" />
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/favorite-destination">
          <p className="menu-drawer-menu-text">Любими дестинации</p>
        </Link>
      ),
      icon: (
        <img
          src={favoriteIcon}
          className="menu-drawer-menu-image"
          alt="destinationIcon"
        />
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/personal-information">
          <p className="menu-drawer-menu-text">Лични данни</p>
        </Link>
      ),
      icon: (
        <img
          src={personalInfromationIcon}
          className="menu-drawer-menu-image"
          alt="destinationIcon"
        />
      ),
    },
    {
      key: "4",
      label: (
        <Link to="/order-history">
          <p className="menu-drawer-menu-text">История на продажбите</p>
        </Link>
      ),
      icon: (
        <img
          src={orderHistoryIcon}
          className="menu-drawer-menu-image"
          alt="destinationIcon"
        />
      ),
    },
    // {
    //   key: "5",
    //   label: (
    //     <Link to="/refund-ticket">
    //       <p className="menu-drawer-menu-text">Отмяна на билети</p>
    //     </Link>
    //   ),
    //   icon: (
    //     <img
    //       src={cancelResrvationIcon}
    //       className="menu-drawer-menu-image"
    //       alt="destinationIcon"
    //     />
    //   ),
    // },
  ];
  return (
    <>
      <Drawer
        closable={false}
        width={window.innerWidth > 760 ? "360px" : "90%"}
        styles={{
          body: {
            padding: window.innerWidth > 760 ? "20px" : "10px",
            paddingTop: "5px",
          },
          header: {
            paddingBottom: "10px",
          },
        }}
        title={<img src={logo} className="menu-drawer-menu-logo" alt="logo" />}
        placement="left"
        onClose={closeMenuDrawer}
        open={openMenuDrawer}
        footer={
          <Button
            className="custom-button ticket-drawer-pay-button"
            size="large"
            type="primary"
            onClick={closeMenuDrawer}
          >
            Назад
          </Button>
        }
      >
        <Menu
          className="side-drawer-menu"
          onClick={closeMenuDrawer}
          mode="inline"
          items={items}
        />
      </Drawer>
    </>
  );
};

export default MenuDrawer;
