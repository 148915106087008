import { apiURl } from "../configuration/apiconfig";
import { fetchAndReturn } from "../utils/utility";

const getAuthTokenRequest = () => {
  const url = `${apiURl}/authenticate?Company=_LIBERTY&Nick=LibertyWWW&Pass=2cba2da37f4d48bb6b0005623b6b6e7f`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchAndReturn(url, parameters);
};

const getCompaniesRequest = (token) => {
  const url = `${apiURl}/companies`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

export { getAuthTokenRequest, getCompaniesRequest };
