import React from "react";
import { useSelector } from "react-redux";
import { Result, Button } from "antd";
import Loading from "../../Loading/Loading";
import warningImage from "../../../images/alert-sign.png";

const ExpireSuccessTickets = () => {
  const { sendTicketPaymentLoading } = useSelector(
    (state) => state.ticketReducer
  );

  return sendTicketPaymentLoading === true ? (
    <Loading />
  ) : (
    <div className="expire-success-payment-container">
      <Result
        icon={
          <img
            className="empty-favorite-destination-image"
            src={warningImage}
            alt="noSchedule"
          />
        }
        title="Няма намерени резултати"
        subTitle={
          <p style={{ fontSize: "18px" }}>
            Моля, проверете своята електронна поща за информация относно
            билетите <br /> или разгледайте Историята на продажбите за
            подробности.
          </p>
        }
        extra={[
          <a href="/order-history" key="2">
            <Button
              size="large"
              type="primary"
              className="custom-button expire-ticket-payment-result-back-button"
            >
              История на продажбите
            </Button>
          </a>,
        ]}
      />
    </div>
  );
};

export default ExpireSuccessTickets;
