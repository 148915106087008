import React from "react";
import { Button, Popconfirm, Divider } from "antd";
import { useSelector } from "react-redux";
import removeItemIcon from "../../images/cancel.png";
import addButtonImage from "../../images/plus.png";
import EmptyFavoriteDestination from "./EmptyFavoriteDestination";
const FaveoriteDestinations = ({
  setOpenAddDestinationModal,
  favoriteDestination,
  setFavoriteDestination,
}) => {
  const { cities } = useSelector((state) => state.citiesReducer);

  const findCity = (id) => {
    return cities.find((city) => city.id === Number(id))?.name;
  };

  const removeItem = (index) => {
    const updatedItems = favoriteDestination.filter((_, i) => i !== index);
    localStorage.setItem("favoriteDestination", JSON.stringify(updatedItems));
    setFavoriteDestination(updatedItems);
  };

  return (
    <>
      <div className="favorite-destination-settings-container">
        <div className="favorite-destination-settings-form-container">
          <div className="favorite-destination-settings-form">
            {favoriteDestination.length === 0 ? (
              <>
                <EmptyFavoriteDestination
                  setOpenAddDestinationModal={setOpenAddDestinationModal}
                />
              </>
            ) : (
              <>
                <div className="favorite-destination-settings-items-container">
                  <h1 className="favorite-destination-settings-heading">
                    Любими дестинации
                  </h1>
                </div>
                <div
                  className="favorite-destination-settings-button"
                  onClick={() => {
                    setOpenAddDestinationModal(true);
                  }}
                >
                  <img src={addButtonImage} width="20px" alt="add" />
                  <h4>Добавяне</h4>
                </div>
                <Divider />
                <div className="favorite-destination-items-container">
                  {favoriteDestination.map((item, index) => (
                    <div key={index}>
                      <span className="favorite-destination-items-number">
                        0{index + 1}
                      </span>
                      <div className="favorite-destination-items-cities">
                        <h2 className="favorite-destination-items-cities-text">
                          {findCity(item.cityFrom)} - {findCity(item.cityTo)}
                        </h2>
                      </div>
                      <Popconfirm
                        title="Изтриване"
                        description="Желаете ли да изтриете дестинацията?"
                        onConfirm={() => removeItem(index)}
                        okText="Да"
                        cancelText="Не"
                      >
                        <Button
                          icon={
                            <img
                              src={removeItemIcon}
                              width="13px"
                              alt="delete"
                            />
                          }
                          danger
                          ghost
                        >
                          Изтриване
                        </Button>
                      </Popconfirm>
                      <Divider />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="favorite-destination-column-image"></div>
      </div>
    </>
  );
};

export default FaveoriteDestinations;
