import { apiURl } from "../configuration/apiconfig";
import { fetchAndReturn } from "../utils/utility";

const getSchedulesRequest = (token, companyId, from, to, date) => {
  const url = `${apiURl}/companies/${companyId}/schedules?forDate=${date}&fromCity=${from}&toCity=${to}`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const getScheduleStopsRequest = (token, companyId, kursId) => {
  const url = `${apiURl}/companies/${companyId}/course/${kursId}/info`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

export { getSchedulesRequest, getScheduleStopsRequest };
