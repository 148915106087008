import React from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";

const myposUrl = () => {
  if (window.location.href.includes("https://tickets.liberty.unrealsoft.bg/")) {
    return "https://demo.mypos.eu/vmp/checkout";
  } else if (window.location.href.includes("https://tickets.libertyap.bg/")) {
    return "https://www.mypos.com/vmp/checkout";
  }
};

const MyPos = () => {
  const { myposPaymentInformation } = useSelector(
    (state) => state.ticketReducer
  );

  const onFinish = (values) => {};
  return (
    <Form id="mypos" method="POST" action={myposUrl()} onFinish={onFinish}>
      <input
        type="hidden"
        name="IPCmethod"
        value={myposPaymentInformation.IPCmethod}
      ></input>
      <input
        type="hidden"
        name="return_url_method"
        value={myposPaymentInformation.ReturnUrlParameter}
      ></input>
      <input
        type="hidden"
        name="IPCVersion"
        value={myposPaymentInformation.IPCVersion}
      ></input>
      <input
        type="hidden"
        name="IPCLanguage"
        value={myposPaymentInformation.IPCLanguage}
      ></input>
      <input
        type="hidden"
        name="SID"
        value={myposPaymentInformation.SID}
      ></input>
      <input
        type="hidden"
        name="WalletNumber"
        value={myposPaymentInformation.WalletNumber}
      ></input>
      <input
        type="hidden"
        name="OrderID"
        value={myposPaymentInformation.OrderID}
      ></input>
      <input
        type="hidden"
        name="URL_OK"
        value={myposPaymentInformation.URL_OK}
      ></input>
      <input
        type="hidden"
        name="URL_Cancel"
        value={myposPaymentInformation.URL_Cancel}
      ></input>
      <input
        type="hidden"
        name="URL_Notify"
        value={myposPaymentInformation.URL_Notify}
      ></input>
      <input
        type="hidden"
        name="CardTokenRequest"
        value={myposPaymentInformation.CardTokenRequest}
      ></input>
      <input
        type="hidden"
        name="KeyIndex"
        value={myposPaymentInformation.KeyIndex}
      ></input>
      <input
        type="hidden"
        name="PaymentParametersRequired"
        value={myposPaymentInformation.PaymentParametersRequired}
      ></input>
      <input
        type="hidden"
        name="SuccessPageRedirectTime"
        value={myposPaymentInformation.SuccessPageRedirectTime}
      ></input>
      <input
        type="hidden"
        name="CartItems"
        value={myposPaymentInformation.CartItems}
      ></input>

      {Array.from({ length: myposPaymentInformation.CartItems }).map(
        (_, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              name={`Article_${index + 1}`}
              value={myposPaymentInformation[`Article_${index + 1}`]}
            ></input>
            <input
              type="hidden"
              name={`Quantity_${index + 1}`}
              value={myposPaymentInformation[`Quantity_${index + 1}`]}
            ></input>
            <input
              type="hidden"
              name={`Price_${index + 1}`}
              value={myposPaymentInformation[`Price_${index + 1}`]}
            ></input>
            <input
              type="hidden"
              name={`Amount_${index + 1}`}
              value={myposPaymentInformation[`Amount_${index + 1}`]}
            ></input>
            <input
              type="hidden"
              name={`Currency_${index + 1}`}
              value={myposPaymentInformation[`Currency_${index + 1}`]}
            ></input>
          </React.Fragment>
        )
      )}

      <input
        type="hidden"
        name="Amount"
        value={myposPaymentInformation.Amount}
      ></input>
      <input
        type="hidden"
        name="Currency"
        value={myposPaymentInformation.Currency}
      ></input>
      <input
        type="hidden"
        name="Signature"
        value={myposPaymentInformation.Signature}
      ></input>
      <input
        style={{ display: "none" }}
        className="my-pos-input"
        type="submit"
        value="Submit"
      ></input>
    </Form>
  );
};

export default MyPos;
