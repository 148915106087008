import actions from "./actions";

const initState = {
  token: "",
  companies: [],
  selectedLanguage: localStorage.getItem("language") || "bg",
  showChooseDestinationText: true,
  openMenuDrawer: false,
};

export default function mainReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };

    case actions.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.companies,
      };

    case actions.SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.value,
      };

    case actions.SET_SHOW_CHOOSE_DESTINATION_TEXT:
      return {
        ...state,
        showChooseDestinationText: action.value,
      };

    case actions.SET_OPEN_MENU_DRAWER:
      return {
        ...state,
        openMenuDrawer: action.value,
      };

    default:
      return state;
  }
}
