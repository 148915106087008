import { Tooltip as ReactTooltip } from "react-tooltip";

export const EmptySpace = () => (
  <>
    <div data-tooltip-id="tooltip-empty-space"></div>
    <ReactTooltip
      id={`tooltip-empty-space`}
      place="top"
      variant="dark"
      content="Empty Space"
    />
  </>
);
