import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../images/logo.png";
import mainActions from "../../redux/main/actions";
import "./index.css";
const Header = () => {
  const dispatch = useDispatch();
  const { setOpenMenuDrawer } = mainActions;

  const handleOpenMenuDrawer = () => {
    dispatch(setOpenMenuDrawer(true));
  };

  return (
    <>
      <header className="header">
        <nav className="navbar nav-container">
          <a href="https://libertyap.bg/">
            <img src={logo} className="navbar-logo" alt="logo" />
          </a>

          <div className="menu" id="menu">
            <ul className="menu-inner">
              <li className="menu-item">
                <Link to="/" className="menu-link">
                  Начало
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/favorite-destination" className="menu-link">
                  Любими дестинации
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/personal-information" className="menu-link">
                  Лични данни
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/order-history" className="menu-link">
                  История на продажбите
                </Link>
              </li>
            </ul>
          </div>
          <div onClick={handleOpenMenuDrawer} className="burger">
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
