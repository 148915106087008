import React from "react";
import { Input } from "antd";
const TicketInformationForm = ({
  setPersonName,
  setPersonEmail,
  setPersonPhone,
  checkEmptyInput,
  personName,
  personEmail,
  personPhone,
  errorEmail,
  setErrorEmail,
}) => {
  const getPersonName = (e) => {
    setPersonName(e.target.value);
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const getPersonEmail = (e) => {
    const email = e.target.value;
    setPersonEmail(email);
    if (emailRegex.test(email)) {
      setErrorEmail("");
    } else {
      setErrorEmail("Моля, въведете валидна Ел.поща");
    }
  };

  const getPersonPhone = (e) => {
    const phone = e.target.value;
    setPersonPhone(phone);
  };

  return (
    <div className="ticket-information-form-container">
      <p className="ticket-information-form-heading remove-pointer-events">
        Лични данни
      </p>
      <Input
        size="large"
        className={`${
          checkEmptyInput && personName?.length === 0 && "empty-input-error"
        } ticket-information-form-input`}
        placeholder="Име"
        onChange={(e) => getPersonName(e)}
        value={personName}
      />
      {errorEmail && <div className="error-message">{errorEmail}</div>}
      <Input
        size="large"
        value={personEmail}
        className={`${
          checkEmptyInput &&
          (personEmail?.length === 0 || errorEmail) &&
          "empty-input-error"
        } ticket-information-form-input`}
        placeholder="Ел.поща"
        onChange={(e) => getPersonEmail(e)}
      />
      <Input
        value={personPhone}
        size="large"
        className={`${
          checkEmptyInput && personPhone?.length === 0 && "empty-input-error"
        } ticket-information-form-input`}
        placeholder="Телефонен номер"
        onChange={(e) => getPersonPhone(e)}
      />
    </div>
  );
};

export default TicketInformationForm;
