import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import notFoundImage from "../../images/404.jpg";
import "./index.css";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <img className="not-found-image" src={notFoundImage} alt="notFound" />
      <p className="not-found-message">
        За съжаление страницата, която сте посетили не съществува
      </p>
      <Link to="/">
        <Button
          className="custom-button not-found-button"
          size="large"
          type="primary"
        >
          Начало
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
