import React from "react";
import {
  SafetyOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
const DestinationHeader = () => {
  return (
    <>
      <div className="destination-header-container">
        <p className="destination-header-title remove-pointer-events">
          Закупуване на <br className="destination-header-title-breake" />
          автобусни билети
        </p>
        <div className="destination-header-icon-container">
          <div className="destination-header-icon-item-gap-10">
            <DashboardOutlined className="destination-header-icon-item-icon-28" />
            <p className="destination-header-icon-item-text remove-pointer-events ">
              Бързо
            </p>
          </div>
          <div className="destination-header-icon-item-gap-5">
            <SafetyOutlined className="destination-header-icon-item-icon-32" />
            <p className="destination-header-icon-item-text remove-pointer-events ">
              Сигурно
            </p>
          </div>
          <div className="destination-header-icon-item-gap-10">
            <ClockCircleOutlined className="destination-header-icon-item-icon-28" />
            <p className="destination-header-icon-item-text remove-pointer-events ">
              24/7
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DestinationHeader;
