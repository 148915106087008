import React, { useState, useEffect } from "react";
import FaveoriteDestinations from "../components/FavoriteDestinationSettings/FaveoriteDestinationsSettings";
import AddFavoriteDestinationModal from "../components/FavoriteDestinationSettings/AddFavoriteDestinationModal";
import "../components/FavoriteDestinationSettings/index.css";
const FavoriteDestionationPage = () => {
  const [openAddDestinationModal, setOpenAddDestinationModal] = useState(false);
  const [favoriteDestination, setFavoriteDestination] = useState([]);

  useEffect(() => {
    const storedItems =
      JSON.parse(localStorage.getItem("favoriteDestination")) || [];
    setFavoriteDestination(storedItems);
  }, []);

  // Save items to local storage whenever items change
  useEffect(() => {
    localStorage.setItem(
      "favoriteDestination",
      JSON.stringify(favoriteDestination)
    );
  }, [favoriteDestination]);

  return (
    <>
      {openAddDestinationModal && (
        <AddFavoriteDestinationModal
          favoriteDestination={favoriteDestination}
          setFavoriteDestination={setFavoriteDestination}
          openAddDestinationModal={openAddDestinationModal}
          setOpenAddDestinationModal={setOpenAddDestinationModal}
        />
      )}
      <FaveoriteDestinations
        favoriteDestination={favoriteDestination}
        setOpenAddDestinationModal={setOpenAddDestinationModal}
        setFavoriteDestination={setFavoriteDestination}
      />
    </>
  );
};

export default FavoriteDestionationPage;
