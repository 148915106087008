import React from "react";
import "./index.css";
const TermsOfUse = () => {
  return (
    <>
      <div className="policy-hero">
        <h3 className="policy-hero-heading remove-pointer-events">
          Условия на превозвача
        </h3>
      </div>
      <div className="policy-container remove-pointer-events">
        <p className="policy-text">
          „Либърти АП” ООД е лицензирано Дружество за превоз на пътници с
          автобуси и публикува на сайта информация за предлаганите услуги.
          „Либърти АП” ООД гарантира на своите клиенти бързо, безопасно и
          комфортно пътуване. Компанията разполага с автобуси, които
          демонстрират последните технически постижения и покриват всички
          европейски стандарти на пътническия транспорт. Екип от опитни шофьори
          със съответната квалификация се грижат за удобното и приятно пътуване
          на своите клиенти.
        </p>

        <h3 className="policy-heading">1. Връщане и Презаверка на билет</h3>

        <p className="policy-text">
          Отказ от пътуване - За отказ от пътуване се счита връщането на билета,
          без да е ползван въобще и преди датата на пътуване. В този случай
          Превозвачът възстановява на пътника:100 % от стойността на билета;
          Двупосочен билет се разглежда като едно цяло. При използвана първа
          посока от билета и направен отказ само за втората посока на двупосочен
          билет, не се възстановяват суми.
        </p>

        <h3 className="policy-heading">2. Политика при неявяване</h3>

        <p className="policy-text">
          За неявяване се счита както физическото отсъствие на пътника, така и
          пристигането му след тръгването на автобуса, в тези случаи Превозвачът
          не възстановява суми.
        </p>

        <h3 className="policy-heading">3. Политика за сигурност</h3>

        <p className="policy-text">
          Фирмата си запазва правото да откаже пътуване на пътници в нетрезво
          състояние ( включително последствия от употреба на алкохолни
          напитки/наркотични средства ), както и при проява на  агресивно
          поведение  заплашващо безопасността, здравето и комфорта на останалите
          пътници и екипажа. Сумата по възстановяване на билет в подобен  случай
          ще бъде 100 % . <b>ВАЖНО !!!</b> Условията на Превозвача са , че всеки
          пътник е длъжен по време на пътуване да има в себе си закупен билет
          или разпечатан, ако същият е закупен онлайн, тъй като{" "}
          <b> превозният документ е застраховка за пътника. </b>
        </p>

        <h3 className="policy-heading">4. Багаж</h3>

        <p className="policy-text">
          Преносът на багаж е ограничен според Условията на Превозвача, ако
          условията са нарушени превозвачът може да откаже превоза на багаж или
          постави допълнителни условия. Колко багаж включва билетът ми? Билетът
          Ви дава възможност за определено количество багаж, съответно: един
          ръчен багаж в салона на автобуса до 5 кг и с размери 35 см х 35 см х
          20 см; един куфар в багажното отделение до 10 кг и с размери 80 см х
          50 см х 30 см. В случай, че пътувате с повече багаж, същото трябва
          предварително да бъде заявено и да заплатите съответната такса на
          Превозвача. Всяка допълнителна багажна единица се таксува 5 лв, срещу
          задължително издаване на фиксален бон. Превозвачът може да направи
          отказ за превозване на допълнителен багаж поради невъзможност.
          <b>Важно:</b> Деца до 14 години ненавършени пътуват задължително с
          придружител!
        </p>
      </div>
      <div style={{ height: "50px" }}></div>
    </>
  );
};

export default TermsOfUse;
