import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import warningImage from "../../images/alert-sign.png";

const NoSchedules = () => {
  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (isMobile) {
      const element = document.querySelector("#scrollToNoSchedule");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <div className="no-schedules-container">
        <Result
          icon={
            <img
              className="no-schedules-image"
              src={warningImage}
              alt="noSchedule"
            />
          }
          title="Няма намерени резултати"
          subTitle={
            <p style={{ fontSize: "18px" }}>
              За избраната дата и градове не са намерени налични резултати
            </p>
          }
          extra={[
            <Link to="/" key="home-link">
              <Button
                style={{ width: "150px" }}
                type="primary"
                className="custom-button"
                size="large"
              >
                Начало
              </Button>
            </Link>,
          ]}
        />
      </div>
      <div className="no-schedule-scroll-to-item" id="scrollToNoSchedule"></div>
    </>
  );
};

export default NoSchedules;
