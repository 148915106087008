const actions = {
  GET_SCHEDULES_REQUEST: "GET_SCHEDULES_REQUEST",
  GET_SCHEDULES_SUCCESS: "GET_SCHEDULES_SUCCESS",

  SCHEDULES_LOADER: "SCHEDULES_LOADER",
  SET_SCHEDULES_ID: "SET_SCHEDULES_ID",

  SET_UNLOCK_SCHEDULES_PAGE: "SET_UNLOCK_SCHEDULES_PAGE",

  GET_SCHEDULE_STOPS_REQUEST: "GET_SCHEDULE_STOPS_REQUEST",
  GET_SCHEDULE_STOPS_SUCCESS: "GET_SCHEDULE_STOPS_SUCCESS",

  SET_OPEN_SCHEDULE_INFO_MODAL: "SET_OPEN_SCHEDULE_INFO_MODAL",

  SCHEDULE_STOPS_LOADING: "SCHEDULE_STOPS_LOADING",

  getSchedules: (token, companyId, from, to, date) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_SCHEDULES_REQUEST,
        token,
        companyId,
        from,
        to,
        date,
      });
    };
  },

  setSchedulesId: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SCHEDULES_ID,
        value,
      });
    };
  },

  setUnlockSchedulesPage: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_UNLOCK_SCHEDULES_PAGE,
        value,
      });
    };
  },

  getScheduleStops: (token, companyId, kursId) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_SCHEDULE_STOPS_REQUEST,
        token,
        companyId,
        kursId,
      });
    };
  },

  setOpenScheduleInfoModal: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_OPEN_SCHEDULE_INFO_MODAL,
        value,
      });
    };
  },
};
export default actions;
