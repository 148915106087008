import { all, takeEvery, put, call, delay } from "redux-saga/effects";
import { notification } from "antd";
import actions from "./actions";
import {
  reserveTicketRequest,
  reservedTicketToMyPosRequest,
  cancelTicketReservationRequest,
  getRefundTicketInformationRequest,
  sendSuccessTicketPaymentRequest,
  sendCancelTicketPaymentRequest,
  getTicketsRequest,
  getTicketPDFRequest,
} from "../../services/ticketServices";
import { encryptData, decryptData } from "../../utils/utility";

export function* reserveTicketSaga({ token, companyId, ticketObject }) {
  try {
    const response = yield call(
      reserveTicketRequest,
      token,
      companyId,
      ticketObject
    );

    if (response[0] === 204) {
      notification.error({
        message: "Това място е вече резервирано",
        placement: "top",
      });
      return;
    }

    if (response[0] === 200) {
      const reservedTicket = response[1];
      yield put({
        type: actions.RESERVE_TICKET_SUCCESS,
        reservedTicket,
      });

      notification.success({
        message: "Успешно добавихте билет в кошницата",
        placement: "top",
      });

      // Add ticket to local storage with encryption
      let cartItems =
        decryptData(localStorage.getItem("reservedTickets")) || [];
      cartItems.push(reservedTicket);
      localStorage.setItem("reservedTickets", encryptData(cartItems));

      // Add ticket to cart with encryption
      let localStorageCart = decryptData(localStorage.getItem("cart")) || [];
      localStorageCart.push(ticketObject);
      localStorage.setItem("cart", encryptData(localStorageCart));

      // Dispatch cart updated event
      const event = new Event("cartUpdated");
      window.dispatchEvent(event);
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}

export function* cancelReservetTicketSaga({ token, companyId, ticketId }) {
  try {
    yield call(cancelTicketReservationRequest, token, companyId, ticketId);
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}

export function* myPosReservetTicketSaga({
  token,
  companyId,
  reservedTicket,
  language,
}) {
  try {
    const response = yield call(
      reservedTicketToMyPosRequest,
      token,
      companyId,
      reservedTicket,
      language
    );
    if (response[0] === 200) {
      yield put({
        type: actions.MYPOS_RESERVED_TICKET_SUCCESS,
        myposPaymentInformation: response[1],
      });
      // Delay to ensure the state is updated
      yield delay(100);

      // Submit the form after ensuring myposPaymentInformation is set
      document.getElementById("mypos").submit();

      yield delay(10000);
      yield put({
        type: actions.SET_MYPOS_PAYMENT_INFORMATION_LOADING,
        myposPaymentInformationLoading: false,
      });
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
      yield put({
        type: actions.SET_MYPOS_PAYMENT_INFORMATION_LOADING,
        myposPaymentInformationLoading: false,
      });
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}

export function* getRefundTicketInformationSaga({
  token,
  companyId,
  ticketNumber,
}) {
  try {
    const response = yield call(
      getRefundTicketInformationRequest,
      token,
      companyId,
      ticketNumber
    );
    if (response[0] === 200) {
      yield put({
        type: actions.REFUND_TICKET_INFORMATION_SUCCESS,
        refundTicketInformation: response[1],
      });
    }
    if (response[0] === 400) {
      notification.error({
        message: "Грешен номер на билет",
        placement: "top",
      });
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}

export function* sendSuccessTicketPaymentSaga({
  token,
  myPosCompany,
  myPosOrderId,
}) {
  try {
    const response = yield call(
      sendSuccessTicketPaymentRequest,
      token,
      myPosCompany,
      myPosOrderId
    );
    if (response[0] === 200) {
      yield put({
        type: actions.SEND_SUCCESS_TICKET_PAYMENT_SUCCESS,
        successMyPosTickets: response[1],
      });
      localStorage.removeItem("reservedTickets");
      localStorage.removeItem("cart");
      const event = new Event("cartUpdated");
      window.dispatchEvent(event);
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "Възникна проблем",
      placement: "top",
    });
  } finally {
    yield put({
      type: actions.SET_SEND_TICKET_PAYMENT_LOADING,
      loading: false,
    });
  }
}

export function* sendCancelTicketPaymentSaga({
  token,
  myPosCompany,
  myPosOrderId,
}) {
  try {
    const response = yield call(
      sendCancelTicketPaymentRequest,
      token,
      myPosCompany,
      myPosOrderId
    );
    if (response[0] === 200) {
      localStorage.removeItem("reservedTickets");
      localStorage.removeItem("cart");
      const event = new Event("cartUpdated");
      window.dispatchEvent(event);
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {}
}

export function* getTicketsSaga({ token, companyId, successMyPosTickets }) {
  try {
    const response = yield call(
      getTicketsRequest,
      token,
      companyId,
      successMyPosTickets
    );

    if (response[0] === 200) {
      yield put({
        type: actions.GET_TICKETS_SUCCESS,
        createdTicktes: response[1],
      });
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "Възникна проблем",
      placement: "top",
    });
  }
}

export function* getTicketPDFSaga({ token, companyId, ticketNumber }) {
  try {
    const [status, response] = yield call(
      getTicketPDFRequest,
      token,
      companyId,
      ticketNumber
    );

    if (status === 200) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/pdf")) {
        const blob = yield response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        yield put({
          type: actions.GET_TICKET_PDF_SUCCESS,
        });
      } else {
        notification.error({
          message: "Invalid file format. Expected a PDF.",
          placement: "top",
        });
      }
    } else if (response.status === 500) {
      notification.error({
        message: "Server error. Please try again later.",
        placement: "top",
      });
    } else {
      notification.error({
        message: `Unexpected error: ${response.statusText}`,
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "An error occurred. Please try again later.",
      placement: "top",
    });
    yield put({
      type: actions.GET_TICKET_PDF_FAILURE,
      error: error.message,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.RESERVE_TICKET_REQUEST, reserveTicketSaga),
    yield takeEvery(
      actions.MYPOS_RESERVED_TICKET_REQUEST,
      myPosReservetTicketSaga
    ),
    yield takeEvery(
      actions.CANCEL_TICKET_RESERVATION_REQUEST,
      cancelReservetTicketSaga
    ),
    yield takeEvery(
      actions.REFUND_TICKET_INFORMATION_REQUEST,
      getRefundTicketInformationSaga
    ),
    yield takeEvery(
      actions.SEND_SUCCESS_TICKET_PAYMENT_REQUEST,
      sendSuccessTicketPaymentSaga
    ),
    yield takeEvery(
      actions.SEND_CANCEL_TICKET_PAYMENT_REQUEST,
      sendCancelTicketPaymentSaga
    ),
    yield takeEvery(actions.GET_TICKETS_REQUEST, getTicketsSaga),
    yield takeEvery(actions.GET_TICKET_PDF_REQUEST, getTicketPDFSaga),
  ]);
}
