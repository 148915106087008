import React from "react";
import SuccessTicketsPayment from "../components/BuyTicket/PaymentMessage/SuccessTicketsPayment";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "../components/BuyTicket/PaymentMessage/index.css";
const SucessTicketsPaymentPage = () => {
  return (
    <>
      <Header />
      <SuccessTicketsPayment />
      <Footer />
    </>
  );
};

export default SucessTicketsPaymentPage;
