import React from "react";
import { Result } from "antd";
import warningIcon from "../../images/alert-sign.png";
const EmptyOrderHistory = () => {
  return (
    <Result
      icon={<img alt="warning" src={warningIcon} style={{ width: "100px" }} />}
      status="warning"
      title="Няма намерени резултати"
    />
  );
};

export default EmptyOrderHistory;
