import React from "react";
import PersonalInformationForm from "../components/PersonalInformation/PersonalInformationForm";
import "../components/PersonalInformation/index.css";
const PersonalInfromationPage = () => {
  return (
    <>
      <PersonalInformationForm />
    </>
  );
};

export default PersonalInfromationPage;
