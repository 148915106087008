import { combineReducers } from "redux";

import mainReducer from "./main/reducer";
import citiesReducer from "./cities/reducer";
import schedulesReducer from "./schedules/reducer";
import destinationReducer from "./destinationForm/reducer";
import ticketReducer from "./ticket/reducer";

export default combineReducers({
  mainReducer,
  citiesReducer,
  schedulesReducer,
  destinationReducer,
  ticketReducer,
});
