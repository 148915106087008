const actions = {
  GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",

  GET_TRIP_TO_CITIES_REQUEST: "GET_TRIP_TO_CITIES_REQUEST",
  GET_TRIP_TO_CITIES_SUCCESS: "GET_TRIP_TO_CITIES_SUCCESS",

  getCities: (token, companyId) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_CITIES_REQUEST,
        token,
        companyId,
      });
    };
  },

  getTripToCities: (token, companyId, cityId, date) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_TRIP_TO_CITIES_REQUEST,
        token,
        companyId,
        cityId,
        date,
      });
    };
  },
};
export default actions;
