import React from "react";
import { useSelector } from "react-redux";
import { SeatMap } from "./SeatMap/SeatMap";

const BusSeats = ({ selectedSeat, setSelectedSeat, checkEmptyInput }) => {
  const { schedules, schedulesId } = useSelector(
    (state) => state.schedulesReducer
  );
  const { checkoutTickets } = useSelector((state) => state.ticketReducer);

  const getBusSeatsInformation = (type) => {
    return schedules?.find((schedule) => schedule?.kursId === schedulesId)
      ?.schemePlace?.[type];
  };

  const addMissingSeatsPositions = (schemePlace) => {
    const groupedRows = schemePlace?.reduce((acc, curr) => {
      acc[curr.row] = acc[curr.row] || [];
      acc[curr.row].push(curr);
      return acc;
    }, {});

    for (let row in groupedRows) {
      const positions = groupedRows[row].map((item) => item.pos);
      for (let i = 1; i <= getBusSeatsInformation("posCount"); i++) {
        if (!positions.includes(i)) {
          schemePlace?.push({
            row: parseInt(row),
            pos: i,
            isEmptySpace: true,
          });
        }
      }
    }

    schemePlace?.sort((a, b) => {
      if (a.row === b.row) {
        return a.pos - b.pos;
      }
      return a.row - b.row;
    });

    return schemePlace;
  };

  const updatedSchemePlace = addMissingSeatsPositions(
    getBusSeatsInformation("statusPlaces")
  );
  const transformedArraySeatMap = [];
  const uniqueRows = [...new Set(updatedSchemePlace?.map((item) => item.row))];
  uniqueRows?.forEach((rowId) => {
    const rowItems = updatedSchemePlace
      ?.filter((item) => item.row === rowId)
      ?.map((item, index) => ({
        seatId: item.number,
        gridSeatNum: item.pos,
        isReserved: checkoutTickets
          ?.filter(
            (checkout) =>
              checkout.kursId ===
              schedules?.find((schedule) => schedule?.kursId === schedulesId)
                ?.kursId
          )
          ?.some((number) => number.placeNumber === item.number)
          ? true
          : item.taken,
        seatDisplayName: item.number,
        isEmptySpace: item.isEmptySpace,
      }));
    transformedArraySeatMap.push({
      gridRowId: rowId,
      seats: rowItems,
    });
  });
  const seatMapData = {
    totalArea: getBusSeatsInformation("RowCount"),
    maxSeatPerRow: getBusSeatsInformation("posCount"),
    areas: [
      {
        areaId: 1,
        grids: transformedArraySeatMap,
      },
    ],
  };

  const toggleSelectSeat = (seat) => {
    if (seat.isReserved) {
      return;
    }

    if (selectedSeat && selectedSeat.seatId === seat.seatId) {
      setSelectedSeat(null); // Deselect if already selected
    } else {
      setSelectedSeat(seat); // Select the new seat
    }
  };

  return (
    <>
      <SeatMap
        checkEmptyInput={checkEmptyInput}
        selectedSeat={selectedSeat}
        layout={seatMapData}
        handleSeatClick={toggleSelectSeat}
        selectedSeatIds={selectedSeat ? [selectedSeat.seatId] : []}
      />
    </>
  );
};

export default BusSeats;
