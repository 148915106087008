import React, { useState, useEffect } from "react";
import { Divider, Input, Button, notification } from "antd";

const PersonalInformationForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const storedInfo = localStorage.getItem("personalInfo");
    if (storedInfo) {
      const { name, email, phone } = JSON.parse(storedInfo);
      setName(name);
      setEmail(email);
      setPhone(phone);
    }
  }, []);

  const handleSave = () => {
    const personalInfo = {
      name: name,
      email: email,
      phone: phone,
    };
    localStorage.setItem("personalInfo", JSON.stringify(personalInfo));
    notification.success({
      message: "Успешно запазване",
      placement: "top",
    });
  };

  return (
    <div className="personal-information-container">
      <div className="personal-information-form-container">
        <div>
          <div className="personal-information-form-items-container">
            <h1 className="personal-information-heading">Лични данни</h1>
          </div>
          <p className="personal-information-description">
            Запазете личните си данни за следващо пътуване
          </p>
          <Divider />

          <div className="personal-information-inputs-container">
            <Input
              className="personal-information-input"
              type="text"
              placeholder="Име"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              className="personal-information-input"
              type="text"
              placeholder="Имейл"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              className="personal-information-input"
              type="text"
              placeholder="Телефонен номер"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Button
              className="custom-button personal-information-button"
              type="primary"
              size="large"
              onClick={handleSave}
            >
              Запазване
            </Button>
          </div>
        </div>
      </div>
      <div className="personal-information-column-image"></div>
    </div>
  );
};

export default PersonalInformationForm;
