import { all, takeEvery, put, call } from "redux-saga/effects";
import { notification } from "antd";
import actions from "./actions";
import destinationActions from "../destinationForm/actions";
import {
  getCitiesRequest,
  getTripToCitiesRequest,
} from "../../services/citiesServices";

export function* getCitiesSaga({ token, companyId }) {
  try {
    const response = yield call(getCitiesRequest, token, companyId);
    if (response[0] === 200) {
      yield put({
        type: actions.GET_CITIES_SUCCESS,
        cities: response[1],
      });
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}

export function* getTripToCitiesSaga({ token, companyId, cityId, date }) {
  try {
    const response = yield call(
      getTripToCitiesRequest,
      token,
      companyId,
      cityId,
      date
    );
    if (response[0] === 200) {
      yield put({
        type: actions.GET_TRIP_TO_CITIES_SUCCESS,
        tripToCity: response[1],
      });
      yield put({
        type: destinationActions.SET_DESTINATION_TO,
        destinationTo: undefined,
      });
    }
    if (response[0] === 204) {
      yield put({
        type: actions.GET_TRIP_TO_CITIES_SUCCESS,
        tripToCity: [],
      });
      yield put({
        type: destinationActions.SET_DESTINATION_TO,
        destinationTo: undefined,
      });
    }

    if (response[0] === 500) {
      yield put({
        type: actions.GET_TRIP_TO_CITIES_SUCCESS,
        tripToCity: [],
      });
      yield put({
        type: destinationActions.SET_DESTINATION_TO,
        destinationTo: undefined,
      });
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}
export default function* rootSaga() {
  yield all([yield takeEvery(actions.GET_CITIES_REQUEST, getCitiesSaga)]);
  yield all([
    yield takeEvery(actions.GET_TRIP_TO_CITIES_REQUEST, getTripToCitiesSaga),
  ]);
}
