import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { decryptData } from "../../utils/utility";
import ticketActions from "../../redux/ticket/actions";
import alertImage from "../../images/alert-sign.png";
const ExpireSchedule = () => {
  const dispatch = useDispatch();
  const { addTicketsToCart, cancelTicketReservation } = ticketActions;
  const { companies, token } = useSelector((state) => state.mainReducer);

  const localStorageReservedTickets =
    decryptData(localStorage.getItem("reservedTickets")) || [];

  useEffect(() => {
    if (localStorageReservedTickets.length > 0) {
      localStorageReservedTickets.forEach((ticket) => {
        dispatch(cancelTicketReservation(token, companies[0]?.id, ticket.id));
      });

      localStorage.removeItem("cart");
      dispatch(addTicketsToCart([]));
      localStorage.removeItem("reservedTickets");
      const event = new Event("cartUpdated");
      window.dispatchEvent(event);
      localStorage.removeItem("ticketExpirationTime");
    }
  });

  return (
    <>
      <div className="expire-schedule-container">
        <Result
          icon={
            <img
              className="expire-schedule-image"
              src={alertImage}
              alt="alert"
            />
          }
          title="Часът за тръгване на автобуса вече е изтекъл"
          subTitle="Моля, проверете следващите налични курсове"
          extra={[
            <Link to="/" key="home-link">
              <Button
                style={{ width: "150px" }}
                type="primary"
                className="custom-button"
                size="large"
              >
                Начало
              </Button>
            </Link>,
          ]}
        />
      </div>
    </>
  );
};

export default ExpireSchedule;
