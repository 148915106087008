import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const SchedulesLoader = () => {
  return (
    <div className="schedules-loader-container">
      <Spin
        indicator={<LoadingOutlined spin className="schedules-loader-icon" />}
      />
    </div>
  );
};

export default SchedulesLoader;
