import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "./index.css";

const Footer = () => {
  const { companies } = useSelector((state) => state.mainReducer);
  const [showCopyright, setShowCopyright] = useState(false);
  const year = dayjs().year();

  const toggleCopyright = () => {
    if (window.innerWidth < 1080) {
      setShowCopyright(!showCopyright);
    }
  };

  return (
    <>
      <footer className="footer-container" onClick={toggleCopyright}>
        <div
          className={`footer-copyright-section ${
            showCopyright
              ? "footer-toggle-copyright-section-block"
              : "footer-toggle-copyright-section-none"
          }`}
        >
          <div className="footer-col">
            <p className="footer-text">
              <span className="remove-pointer-events">
                Всички права запазени {companies[0]?.companyName} © {year}{" "}
                <br className="footer-br" /> Powered by{" "}
              </span>
              <a
                className="footer-company-name"
                href="https://www.unrealsoft.bg/"
                target="blank"
              >
                <span className="remove-pointer-events">UnrealSoft</span>
              </a>
            </p>
          </div>
        </div>
        <div
          className={`footer-policy-container ${
            showCopyright
              ? "footer-toggle-copyright-section-block"
              : "footer-toggle-copyright-section-none"
          }`}
        >
          <Link className="footer-policy-link" to="/terms-of-use">
            <p className="footer-policy-text remove-pointer-events">
              Условия на превозвача
            </p>
          </Link>
          <Link className="footer-policy-link" to="/private-policy">
            <p className="footer-policy-text remove-pointer-events">
              Политика за поверителност
            </p>
          </Link>
        </div>
        <div className="footer-information-container">
          <div className="footer-information-number-container">
            <div className="footer-information-number-item">
              <PhoneOutlined className="footer-information-number-icon" />
              <p className="footer-text">
                <a href="tel:+359878940132" style={{ color: "#fff" }}>
                  +359887 028 276
                </a>
              </p>
            </div>
          </div>

          <div className="footer-infromation-email-container">
            <div className="footer-infromation-email-item">
              <MailOutlined className="footer-infromation-email-icon" />
              <p className="footer-text remove-pointer-events">
                libertyap@abv.bg
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
