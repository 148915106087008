import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CryptoJS from "crypto-js";

// Extend dayjs with UTC plugin
dayjs.extend(utc);

const SECRET_KEY = "your-secret-key"; // Replace with your own secret key

export const encryptData = (data) => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  } catch (error) {
    return null;
  }
};

export const decryptData = (ciphertext) => {
  if (!ciphertext) {
    return [];
  }

  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return [];
  }
};

export const dateStringToEpoch = (dateString) => {
  const parsedDate = dayjs.utc(dateString, "DD/MM/YYYY HH:mm");

  // Get the epoch time and return it
  return parsedDate.unix();
};

export const epochToNormalDate = (epoch) => {
  // Convert milliseconds since epoch to seconds
  const date = new Date(epoch * 1000);
  // Get day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero indexed
  const year = date.getFullYear() % 100; // Get last two digits of the year
  // Get UTC hours and minutes
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  // Formatting to dd/mm/yy hh:mm format
  return (
    (day < 10 ? "0" : "") +
    day +
    "/" +
    (month < 10 ? "0" : "") +
    month +
    "/" +
    (year < 10 ? "0" : "") +
    year +
    " " +
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes
  );
};

export const displayScheduleDateFullNameFormat = (schedule) => {
  // Parse the epoch time
  const date = dayjs.unix(schedule);
  // Set the locale to Bulgarian
  dayjs.locale("bg");
  // Format the date
  return date.format("dddd, MMM D, YYYY");
};

export const getBusSeatsCount = (schedule) => {
  const filteredStatusPlaces = schedule?.schemePlace?.statusPlaces?.filter(
    (place) => !place.isEmptySpace
  );
  return filteredStatusPlaces?.length;
};

export async function fetchAndReturn(url, parameters) {
  try {
    const response = await fetch(url, parameters);

    if (response.status === 200) {
      return [response.status, await response.json()];
    } else if (response.status === 204) {
      return [response.status, null];
    } else if (response.status === 400) {
      return [response.status, null];
    } else if (response.status === 500) {
      return [response.status, null];
    }
  } catch (error) {
    throw error;
  }
}
export async function fetchAndReturnPDF(url, parameters) {
  try {
    const response = await fetch(url, parameters);
    const [statusCode, data] = [response.status, response];
    return [statusCode, data];
  } catch (error) {
    throw error;
  }
}
// Manually define Bulgarian locale
export const bulgarianLocaleDaysAndMonths = {
  name: "bg",
  weekdays: [
    "неделя",
    "понеделник",
    "вторник",
    "сряда",
    "четвъртък",
    "петък",
    "събота",
  ],
  months: [
    "януари",
    "февруари",
    "март",
    "април",
    "май",
    "юни",
    "юли",
    "август",
    "септември",
    "октомври",
    "ноември",
    "декември",
  ],
  weekdaysShort: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
  monthsShort: [
    "ян.",
    "фев.",
    "март",
    "апр.",
    "май",
    "юни",
    "юли",
    "авг.",
    "септ.",
    "окт.",
    "ноем.",
    "дек.",
  ],
};

export const bulgarianLocalCalandarText = {
  locale: "bg",
  placeholder: "Изберете дата",
  yearPlaceholder: "Изберете година",
  monthPlaceholder: "Изберете месец",
  dayPlaceholder: "Изберете ден",
  today: "Днес",
  now: "Сега",
  backToToday: "Към днес",
  ok: "ОК",
  clear: "Изчисти",
  month: "Месец",
  year: "Година",
  timeSelect: "Изберете време",
  dateSelect: "Изберете дата",
  monthSelect: "Изберете месец",
  yearSelect: "Изберете година",
  decadeSelect: "Изберете десетилетие",
  yearFormat: "YYYY",
  dateFormat: "DD/MM/YYYY",
  dayFormat: "D",
  dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
  monthFormat: "MM",
  monthBeforeYear: true,
  previousMonth: "Предишен месец (PageUp)",
  nextMonth: "Следващ месец (PageDown)",
  previousYear: "Предишна година (Control + left)",
  nextYear: "Следваща година (Control + right)",
  previousDecade: "Предишно десетилетие",
  nextDecade: "Следващо десетилетие",
  previousCentury: "Предишен век",
  nextCentury: "Следващ век",
};
