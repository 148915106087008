import React from "react";
import { Divider } from "antd";
import OrderHistory from "../components/OrderHistory/OrderHistory";
import EmptyOrderHistory from "../components/OrderHistory/EmptyOrderHistory";
const OrderHistoryPage = () => {
  const storedTickets = localStorage.getItem("createdTickets");
  let createdTickets = [];

  if (storedTickets) {
    try {
      createdTickets = JSON.parse(storedTickets);
    } catch (error) {}
  }

  return (
    <div className="order-history-page-container">
      <div className="order-history-form-container">
        <div>
          <div className="order-history-form-items-container">
            <h1 className="order-history-heading">История на билетите</h1>
          </div>
          <p className="order-history-description">
            Проследете последните <b>10</b> предишни покупки на билети
          </p>
          <Divider />
          {createdTickets.length > 0 ? <OrderHistory /> : <EmptyOrderHistory />}
        </div>
      </div>
      <div className="order-history-column-image"></div>
    </div>
  );
};

export default OrderHistoryPage;
