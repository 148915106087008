const actions = {
  SET_DESTINATION_FROM: "SET_DESTINATION_FROM",
  SET_DESTINATION_TO: "SET_DESTINATION_TO",
  SET_DESTINATION_DATE: "SET_DESTINATION_DATE",

  setDestinationFrom: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_DESTINATION_FROM,
        value,
      });
    };
  },

  setDestinationTo: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_DESTINATION_TO,
        value,
      });
    };
  },
  setDestinationDate: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_DESTINATION_DATE,
        value,
      });
    };
  },
};
export default actions;
