import actions from "./actions";

const initState = {
  reservedTicket: [],
  myposPaymentInformation: [],
  checkoutTickets: [],
  openCheckoutDrawer: false,
  myposPaymentInformationLoading: false,
  refundTicketInformation: [],
  createdTicktes: [],
  ticketsExpired: false,
  successMyPosTickets: [],
  sendTicketPaymentLoading: false,
};

export default function ticketReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RESERVE_TICKET_SUCCESS:
      return {
        ...state,
        reservedTicket: [...state.reservedTicket, action.reservedTicket],
      };

    case actions.MYPOS_RESERVED_TICKET_REQUEST:
      return {
        ...state,
        myposPaymentInformationLoading: true,
      };

    case actions.MYPOS_RESERVED_TICKET_SUCCESS:
      return {
        ...state,
        myposPaymentInformation: action.myposPaymentInformation,
      };

    case actions.REFUND_TICKET_INFORMATION_SUCCESS:
      return {
        ...state,
        refundTicketInformation: action.refundTicketInformation,
      };

    case actions.SET_MYPOS_PAYMENT_INFORMATION_LOADING:
      return {
        ...state,
        myposPaymentInformationLoading: action.myposPaymentInformationLoading,
      };

    case actions.ADD_TICKETS_TO_CART:
      return {
        ...state,
        checkoutTickets: action.value,
      };

    case actions.SET_TICKETS_EXPIRED:
      return {
        ...state,
        ticketsExpired: action.value,
      };

    case actions.SET_OPEN_CHECKOUT_DRAWER:
      return {
        ...state,
        openCheckoutDrawer: action.value,
      };

    case actions.GET_TICKETS_SUCCESS:
      return {
        ...state,
        createdTicktes: action.createdTicktes,
      };

    case actions.SEND_SUCCESS_TICKET_PAYMENT_SUCCESS:
      return {
        ...state,
        successMyPosTickets: action.successMyPosTickets,
        sendTicketPaymentLoading: false,
      };

    case actions.SET_SEND_TICKET_PAYMENT_LOADING:
      return {
        ...state,
        sendTicketPaymentLoading: action.loading,
      };

    default:
      return state;
  }
}
