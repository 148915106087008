import React from "react";
import { Modal, Steps, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import "./index.css";
import busImage from "../../../images/bus-stop.png";
import schedulesActions from "../../../redux/schedules/actions";
const { Title, Paragraph } = Typography;

const ShowSchedulesStopsModal = () => {
  const dispatch = useDispatch();

  const { setOpenScheduleInfoModal } = schedulesActions;

  const handleCancelModal = () => {
    dispatch(setOpenScheduleInfoModal(false));
  };

  const { scheduleStops, openScheduleInfoModal } = useSelector(
    (state) => state.schedulesReducer
  );

  const timelineStops = scheduleStops?.map((city) => ({
    title: <p style={{ lineHeight: 1 }}>{city.cityName}</p>,
    description: (
      <h3>{dayjs.unix(city.cityStartTime).utcOffset(0).format("HH:mm")}</h3>
    ),
  }));

  const firstHalf = timelineStops.slice(0, Math.ceil(timelineStops.length / 2));
  const secondHalf = timelineStops.slice(Math.ceil(timelineStops.length / 2));

  return (
    <>
      <Modal
        footer={null}
        centered
        open={openScheduleInfoModal}
        onCancel={handleCancelModal}
      >
        <Title level={3}>Информация за пътуване</Title>

        <Paragraph style={{ color: "#595959" }}>
          Часовете на тръгване и пристигане са приблизителни и Либърти не носи
          отговорност за малки закъснения.
        </Paragraph>
        {scheduleStops.length > 0 ? (
          <div
            style={{
              display: "flex",
            }}
          >
            <Steps
              direction="vertical"
              current={0} // това се игнорира със стиловете по-долу
              items={firstHalf.map((step, index) => ({
                ...step,
                status: "process", // настройка за да показва всички като активни
                icon: (
                  <span className="step-icon">
                    {/* <FieldTimeOutlined /> */}
                    <img
                      src={busImage}
                      alt="bus"
                      style={{ width: "30px", verticalAlign: "middle" }}
                    />
                  </span>
                ), // стил за син цвят и числа
              }))}
            />
            <Steps
              direction="vertical"
              current={0} // това се игнорира със стиловете по-долу
              items={secondHalf.map((step, index) => ({
                ...step,
                status: "process", // настройка за да показва всички като активни
                icon: (
                  <span className="step-icon">
                    {/* <FieldTimeOutlined /> */}
                    <img
                      src={busImage}
                      alt="bus"
                      style={{ width: "30px", verticalAlign: "middle" }}
                    />
                  </span>
                ), // стил за син цвят и числа
              }))}
            />
          </div>
        ) : (
          "Nqm"
        )}
      </Modal>
    </>
  );
};

export default ShowSchedulesStopsModal;
