import React from "react";
import { Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ticketActions from "../../redux/ticket/actions";
import schedulesActions from "../../redux/schedules/actions";
import { dateStringToEpoch, decryptData } from "../../utils/utility";

const BuyTicketButton = ({
  selectedSeat,
  personName,
  personEmail,
  personPhone,
  priceType,
  priceId,
  priceAmount,
  setCheckEmptyInput,
  errorEmail,
  setSelectedSeat,
  ticketName,
}) => {
  const dispatch = useDispatch();
  const { getSchedules } = schedulesActions;
  const [messageApi, messageHolder] = message.useMessage();

  const { addTicketsToCart, reserveTicket, setTicketsExpired } = ticketActions;
  const { companies, token } = useSelector((state) => state.mainReducer);
  const { schedules, schedulesId } = useSelector(
    (state) => state.schedulesReducer
  );
  const { checkoutTickets } = useSelector((state) => state.ticketReducer);
  const { destinationFrom, destinationTo, destinationDate } = useSelector(
    (state) => state.destinationReducer
  );

  const getExactSchedule = schedules?.find(
    (schedule) => schedule?.kursId === schedulesId
  );
  const localStorageCart = decryptData(localStorage.getItem("cart")) || [];
  const reserveTicketObject = {
    forDate: getExactSchedule?.scheduleStart,
    kursId: getExactSchedule?.kursId,
    priceId: priceId,
    priceTypeId: priceType,
    forPerson: personName,
    phone: personPhone,
    email: personEmail,
    placeNumber: selectedSeat?.seatId,
    twoWay: false,
    fromCity: getExactSchedule?.fromCity,
    toCity: getExactSchedule?.toCity,
    price: priceAmount,
    sector: getExactSchedule?.sector,
    scheduleEnd: getExactSchedule?.scheduleEnd,
    scheduleStart: getExactSchedule?.scheduleStart,
    ticketName: ticketName,
  };

  const fillEmptyInputsError = () => {
    messageApi.open({
      type: "error",
      content: (
        <span className="remove-pointer-events">Попълнете празните полета</span>
      ),
    });
  };

  const addTicketToCart = async () => {
    setCheckEmptyInput(true);
    if (
      selectedSeat &&
      personName &&
      !errorEmail &&
      personEmail &&
      personPhone &&
      priceType &&
      priceId
    ) {
      if (localStorageCart?.length === 0) {
        const expirationTime = new Date().getTime() + 5 * 60 * 1000;
        localStorage.setItem("ticketExpirationTime", expirationTime);
      }
      dispatch(addTicketsToCart([...checkoutTickets, reserveTicketObject]));
      setSelectedSeat(null);
      setCheckEmptyInput(false);
      dispatch(reserveTicket(token, companies[0]?.id, reserveTicketObject));
      setTimeout(() => {
        dispatch(
          getSchedules(
            token,
            companies[0]?.id,
            destinationFrom,
            destinationTo,
            dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
          )
        );
      }, 500);
      dispatch(setTicketsExpired(false));
    } else {
      fillEmptyInputsError();
    }
  };

  return (
    <>
      {messageHolder}
      <Button
        onClick={addTicketToCart}
        className="buy-ticket-button custom-button"
        size="large"
        type="primary"
      >
        <span className="remove-pointer-events">Добавяне в кошницата</span>
      </Button>
    </>
  );
};

export default BuyTicketButton;
