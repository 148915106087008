import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Divider,
  Modal,
  Select,
  Button,
  notification,
  Result,
  Input,
} from "antd";
import citiesActions from "../../redux/cities/actions";
import { dateStringToEpoch } from "../../utils/utility";
import globeImage from "../../images/globe.png";
const AddFavoriteDestinationModal = ({
  openAddDestinationModal,
  setOpenAddDestinationModal,
  setFavoriteDestination,
}) => {
  const { Option } = Select;

  const dispatch = useDispatch();
  const { getTripToCities } = citiesActions;
  const { token, companies, selectedLanguage } = useSelector(
    (state) => state.mainReducer
  );
  const { cities, tripToCity } = useSelector((state) => state.citiesReducer);
  const [searchValueFrom, setSearchValueFrom] = useState("");
  const [searchValueTo, setSearchValueTo] = useState("");

  const [cityFrom, setCityFrom] = useState("");
  const [cityTo, setCityTo] = useState("");

  const { destinationDate } = useSelector((state) => state.destinationReducer);
  const [checkEmptyDestinationInput, setCheckEmptyDestinationInput] =
    useState(false);

  const citiesOptions = (array) => {
    return array.map((city) => {
      return {
        value: city.id,
        label: selectedLanguage === "bg" ? city?.name : city?.nameLat,
      };
    });
  };

  const addItem = () => {
    setCheckEmptyDestinationInput(true);
    if (typeof cityFrom === "number" && typeof cityTo === "number") {
      const newItem = { cityFrom, cityTo };

      setFavoriteDestination((prevItems) => {
        // Check if the newItem already exists in the favoriteDestination array
        const itemExists = prevItems.some(
          (item) => item.cityFrom === cityFrom && item.cityTo === cityTo
        );

        // If the item doesn't exist, add it to the array
        if (!itemExists) {
          setOpenAddDestinationModal(false);
          setCityFrom("");
          setCityTo("");
          return [...prevItems, newItem];
        } else {
          // Optionally, you can handle the case where the item already exists
          notification.error({
            message: "Тази дестинация вече съществува",
            placement: "top",
          });
          return prevItems; // Return the existing array without changes
        }
      });
    }
  };

  const getFromDestinationValue = (value) => {
    setCityFrom(value);
  };

  const handleGetSearchFromValue = (value) => {
    setSearchValueFrom(value);
  };

  const handleGetSearchToValue = (value) => {
    setSearchValueTo(value);
  };

  const getToDestinationValue = (value) => {
    setCityTo(value);
  };

  const getTripToCity = (value) => {
    getFromDestinationValue(value);
    if (value) {
      dispatch(
        getTripToCities(
          token,
          companies[0]?.id,
          value,
          dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
        )
      );
    }
  };

  const searchDestinationFrom = (searchedValue, getSearchedValue) => (
    <Input
      variant="borderless"
      placeholder="Търсене"
      value={searchedValue}
      onChange={(e) => getSearchedValue(e.target.value)}
    />
  );

  const filteredCities = (cityArray, searchedValue) => {
    return cityArray.filter((city) =>
      selectedLanguage === "bg"
        ? city?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase())
        : city?.nameLat?.toLowerCase()?.includes(searchedValue?.toLowerCase())
    );
  };

  return (
    <Modal
      centered
      footer={null}
      open={openAddDestinationModal}
      onCancel={() => setOpenAddDestinationModal(false)}
    >
      <h3 className="add-favorite-destination-modal-heading">
        Любима дестинация
      </h3>
      <Divider className="add-favorite-destination-modal-divider" />
      <div className="add-favorite-destination-modal-container">
        <div>
          <Select
            className="add-favorite-destination-modal-select"
            dropdownRender={(menu) => (
              <div>
                {searchDestinationFrom(
                  searchValueFrom,
                  handleGetSearchFromValue
                )}
                {menu}
              </div>
            )}
            value={cityFrom || undefined}
            status={
              checkEmptyDestinationInput && cityFrom?.length === 0 && "error"
            }
            size="large"
            onChange={(value) => {
              getTripToCity(value);
              setCityTo("");
            }}
            placeholder="От къде"
            notFoundContent={
              <Result
                icon={null}
                title={
                  <p className="destination-form-select-empty-title remove-pointer-events">
                    Няма намерени градове
                  </p>
                }
              />
            }
          >
            {citiesOptions(filteredCities(cities, searchValueFrom)).map(
              (option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  <span className="destination-form-select-option remove-pointer-events">
                    {option.label}
                  </span>
                </Option>
              )
            )}
          </Select>
          <Select
            className="add-favorite-destination-modal-select"
            value={cityTo || undefined}
            dropdownRender={(menu) => (
              <div>
                {searchDestinationFrom(searchValueTo, handleGetSearchToValue)}
                {menu}
              </div>
            )}
            status={
              checkEmptyDestinationInput && cityTo?.length === 0 && "error"
            }
            size="large"
            onChange={getToDestinationValue}
            placeholder="До къде"
            notFoundContent={
              <Result
                icon={null}
                title={
                  <p className="destination-form-select-empty-title remove-pointer-events">
                    Няма намерени градове
                  </p>
                }
              />
            }
          >
            {citiesOptions(filteredCities(tripToCity, searchValueTo)).map(
              (option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  <span className="destination-form-select-option remove-pointer-events">
                    {option.label}
                  </span>
                </Option>
              )
            )}
          </Select>
          <Button
            className="custom-button add-favorite-destination-modal-add"
            type="primary"
            size="large"
            onClick={addItem}
          >
            Запазване
          </Button>
        </div>
        <div>
          <img
            className="add-favorite-destination-modal-globe-image"
            src={globeImage}
            alt="globeImage"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddFavoriteDestinationModal;
