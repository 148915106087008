const actions = {
  RESERVE_TICKET_REQUEST: "RESERVE_TICKET_REQUEST",
  RESERVE_TICKET_SUCCESS: "RESERVE_TICKET_SUCCESS",

  CANCEL_TICKET_RESERVATION_REQUEST: "CANCEL_TICKET_RESERVATION_REQUEST",
  CANCEL_TICKET_RESERVATION_SUCCESS: "CANCEL_TICKET_RESERVATION_SUCCESS",

  MYPOS_RESERVED_TICKET_REQUEST: "MYPOS_RESERVED_TICKET_REQUEST",
  MYPOS_RESERVED_TICKET_SUCCESS: "MYPOS_RESERVED_TICKET_SUCCESS",

  REFUND_TICKET_INFORMATION_REQUEST: "REFUND_TICKET_INFORMATION_REQUEST",
  REFUND_TICKET_INFORMATION_SUCCESS: "REFUND_TICKET_INFORMATION_SUCCESS",

  SEND_SUCCESS_TICKET_PAYMENT_REQUEST: "SEND_SUCCESS_TICKET_PAYMENT_REQUEST",
  SEND_SUCCESS_TICKET_PAYMENT_SUCCESS: "SEND_SUCCESS_TICKET_PAYMENT_SUCCESS",

  SEND_CANCEL_TICKET_PAYMENT_REQUEST: "SEND_CANCEL_TICKET_PAYMENT_REQUEST",
  SEND_CANCEL_TICKET_PAYMENT_SUCCESS: "SEND_CANCEL_TICKET_PAYMENT_SUCCESS",

  GET_TICKET_PDF_REQUEST: "GET_TICKET_PDF_REQUEST",
  GET_TICKET_PDF_SUCCESS: "GET_TICKET_PDF_SUCCESS",

  GET_TICKETS_REQUEST: "GET_TICKETS_REQUEST",
  GET_TICKETS_SUCCESS: "GET_TICKETS_SUCCESS",

  ADD_TICKETS_TO_CART: "ADD_TICKETS_TO_CART",

  SET_OPEN_CHECKOUT_DRAWER: "SET_OPEN_CHECKOUT_DRAWER",

  SET_TICKETS_EXPIRED: "SET_TICKETS_EXPIRED",

  SET_MYPOS_PAYMENT_INFORMATION_LOADING:
    "SET_MYPOS_PAYMENT_INFORMATION_LOADING",

  SET_SEND_TICKET_PAYMENT_LOADING: "SET_SEND_TICKET_PAYMENT_LOADING",

  reserveTicket: (token, companyId, ticketObject) => {
    return (dispatch) => {
      dispatch({
        type: actions.RESERVE_TICKET_REQUEST,
        token,
        companyId,
        ticketObject,
      });
    };
  },

  myposReservedTicket: (token, companyId, reservedTicket, language) => {
    return (dispatch) => {
      dispatch({
        type: actions.MYPOS_RESERVED_TICKET_REQUEST,
        token,
        companyId,
        reservedTicket,
        language,
      });
    };
  },

  addTicketsToCart: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_TICKETS_TO_CART,
        value,
      });
    };
  },

  cancelTicketReservation: (token, companyId, ticketId) => {
    return (dispatch) => {
      dispatch({
        type: actions.CANCEL_TICKET_RESERVATION_REQUEST,
        token,
        companyId,
        ticketId,
      });
    };
  },

  setOpenCheckoutDrawer: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_OPEN_CHECKOUT_DRAWER,
        value,
      });
    };
  },

  getRefundTicketInformation: (token, companyId, ticketNumber) => {
    return (dispatch) => {
      dispatch({
        type: actions.REFUND_TICKET_INFORMATION_REQUEST,
        token,
        companyId,
        ticketNumber,
      });
    };
  },

  sendSuccessTicketPayment: (token, myPosCompany, myPosOrderId) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SEND_TICKET_PAYMENT_LOADING,
        loading: true,
      });

      dispatch({
        type: actions.SEND_SUCCESS_TICKET_PAYMENT_REQUEST,
        token,
        myPosCompany,
        myPosOrderId,
      });
    };
  },

  sendCancelTicketPayment: (token, myPosCompany, myPosOrderId) => {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_CANCEL_TICKET_PAYMENT_REQUEST,
        token,
        myPosCompany,
        myPosOrderId,
      });
    };
  },

  getCreatedTickets: (token, companyId, successMyPosTickets) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_TICKETS_REQUEST,
        token,
        companyId,
        successMyPosTickets,
      });
    };
  },

  setTicketsExpired: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_TICKETS_EXPIRED,
        value,
      });
    };
  },

  getTicketPDF: (token, companyId, ticketNumber) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_TICKET_PDF_REQUEST,
        token,
        companyId,
        ticketNumber,
      });
    };
  },
};
export default actions;
