import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BuyTicketHeader from "../components/BuyTicket/BuyTicketHeader";
import BusSeats from "../components/BuyTicket/BusSeats/BusSeats";
import TicketInformationForm from "../components/BuyTicket/TicketInformationForm";
import BuyTicketPriceCard from "../components/BuyTicket/BuyTicketPriceCard";
import BuyTicketButton from "../components/BuyTicket/BuyTicketButton";
import ExpireSchedule from "../components/BuyTicket/ExpireSchedule";
import ShowSchedulesStopsModal from "../components/BuyTicket/ShowSchedulesStops/ShowSchedulesStopsModal";
import "../components/BuyTicket/index.css";

const BuyTicketPage = () => {
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [personName, setPersonName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personPhone, setPersonPhone] = useState("");
  const [priceType, setPriceType] = useState("");
  const [priceId, setPriceId] = useState("");
  const [priceAmount, setPriceAmount] = useState("");
  const [ticketName, setTicketName] = useState("");
  const [checkEmptyInput, setCheckEmptyInput] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const { schedules, schedulesId } = useSelector(
    (state) => state.schedulesReducer
  );
  useEffect(() => {
    const storedInfo = localStorage.getItem("personalInfo");
    if (storedInfo) {
      const { name, email, phone } = JSON.parse(storedInfo);
      setPersonName(name);
      setPersonEmail(email);
      setPersonPhone(phone);
    }
  }, []);

  const getExactSchedule = schedules?.find(
    (schedule) => schedule?.kursId === schedulesId
  );

  return (
    <>
      {getExactSchedule ? (
        <>
          <BuyTicketHeader />
          <ShowSchedulesStopsModal />
          <div className="buy-ticket-main-container">
            <div className="buy-ticket-container">
              <div className="buy-ticket-container-item">
                <BuyTicketPriceCard
                  checkEmptyInput={checkEmptyInput}
                  priceType={priceType}
                  priceId={priceId}
                  setPriceType={setPriceType}
                  setPriceId={setPriceId}
                  setPriceAmount={setPriceAmount}
                  setTicketName={setTicketName}
                />
              </div>
              <div className="buy-ticket-container-item">
                <BusSeats
                  selectedSeat={selectedSeat}
                  setSelectedSeat={setSelectedSeat}
                  checkEmptyInput={checkEmptyInput}
                  setCheckEmptyInput={setCheckEmptyInput}
                />
              </div>
              <div className="buy-ticket-container-item">
                <TicketInformationForm
                  setPersonName={setPersonName}
                  setPersonEmail={setPersonEmail}
                  setPersonPhone={setPersonPhone}
                  setPriceType={setPriceType}
                  setPriceId={setPriceId}
                  checkEmptyInput={checkEmptyInput}
                  personName={personName}
                  personEmail={personEmail}
                  personPhone={personPhone}
                  priceType={priceType}
                  priceId={priceId}
                  errorEmail={errorEmail}
                  setErrorEmail={setErrorEmail}
                  setCheckEmptyInput={setCheckEmptyInput}
                />
                <BuyTicketButton
                  selectedSeat={selectedSeat}
                  personName={personName}
                  personEmail={personEmail}
                  personPhone={personPhone}
                  priceType={priceType}
                  priceId={priceId}
                  priceAmount={priceAmount}
                  ticketName={ticketName}
                  checkEmptyInput={checkEmptyInput}
                  setCheckEmptyInput={setCheckEmptyInput}
                  errorEmail={errorEmail}
                  setSelectedSeat={setSelectedSeat}
                  setPersonName={setPersonName}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <ExpireSchedule />
      )}
    </>
  );
};

export default BuyTicketPage;
