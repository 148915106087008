import { apiURl } from "../configuration/apiconfig";
import { fetchAndReturn, fetchAndReturnPDF } from "../utils/utility";

const reserveTicketRequest = (token, companyId, ticketObject) => {
  const url = `${apiURl}/companies/${companyId}/reserve`;
  const parameters = {
    method: "POST",
    body: JSON.stringify(ticketObject),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const cancelTicketReservationRequest = (token, companyId, ticketId) => {
  const url = `${apiURl}/companies/${companyId}/reserve/${ticketId}`;
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const reservedTicketToMyPosRequest = (
  token,
  companyId,
  reservedTicket,
  language
) => {
  const url = `${apiURl}/companies/${companyId}/payment/${language}`;
  const parameters = {
    method: "POST",
    body: JSON.stringify(reservedTicket),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const getRefundTicketInformationRequest = (token, companyId, ticketNumber) => {
  const url = `${apiURl}/companies/${companyId}/ticket/${ticketNumber}/cancellation`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const sendSuccessTicketPaymentRequest = (token, myPosCompany, myPosOrderId) => {
  const url = `${apiURl}/companies/${myPosCompany}/payment/${myPosOrderId}/success`;
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const sendCancelTicketPaymentRequest = (token, myPosCompany, myPosOrderId) => {
  const url = `${apiURl}/companies/${myPosCompany}/payment/${myPosOrderId}/cancel`;
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const getTicketsRequest = (token, companyId, successMyPosTickets) => {
  const url = `${apiURl}/companies/${companyId}/ticket/info`;

  const parameters = {
    method: "PUT",
    body: JSON.stringify(successMyPosTickets),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const getTicketPDFRequest = (token, companyId, ticketNumber) => {
  const url = `${apiURl}/companies/${companyId}/ticket/${ticketNumber}/pdf`;

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturnPDF(url, parameters);
};

export {
  reserveTicketRequest,
  reservedTicketToMyPosRequest,
  cancelTicketReservationRequest,
  getRefundTicketInformationRequest,
  sendSuccessTicketPaymentRequest,
  sendCancelTicketPaymentRequest,
  getTicketsRequest,
  getTicketPDFRequest,
};
