import actions from "./actions";

const initState = {
  schedules: [],
  noSchedules: false,
  schedulesLoading: false,
  schedulesId: 0,
  unlockSchedulesPage: false,
  scheduleStops: [],
  openScheduleInfoModal: false,
  schedulesStopsLoading: false,
};

export default function schedulesReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.GET_SCHEDULES_REQUEST:
      return {
        ...state,
        schedulesLoading: true,
      };

    case actions.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: action.schedules,
        schedulesLoading: false,
      };

    case actions.SCHEDULES_LOADER:
      return {
        ...state,
        noSchedules: action.noSchedules,
      };

    case actions.SET_SCHEDULES_ID:
      return {
        ...state,
        schedulesId: action.value,
      };

    case actions.SET_UNLOCK_SCHEDULES_PAGE:
      return {
        ...state,
        unlockSchedulesPage: action.value,
      };

    case actions.GET_SCHEDULE_STOPS_SUCCESS:
      return {
        ...state,
        scheduleStops: action.scheduleStops,
      };

    case actions.SET_OPEN_SCHEDULE_INFO_MODAL:
      return {
        ...state,
        openScheduleInfoModal: action.value,
      };

    case actions.SCHEDULE_STOPS_LOADING:
      return {
        ...state,
        schedulesStopsLoading: action.schedulesStopsLoading,
      };

    default:
      return state;
  }
}
