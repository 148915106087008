import { all } from "redux-saga/effects";

import mainSaga from "./main/saga";
import citiesSaga from "./cities/saga";
import schedulesSaga from "./schedules/saga";
import ticketSaga from "./ticket/saga";

export default function* rootSaga(getState) {
  yield all([mainSaga(), citiesSaga(), schedulesSaga(), ticketSaga()]);
}
