import { apiURl } from "../configuration/apiconfig";
import { fetchAndReturn } from "../utils/utility";

const getCitiesRequest = (token, companyId) => {
  const url = `${apiURl}/companies/${companyId}/cities?citiesOptions=1`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};

const getTripToCitiesRequest = (token, companyId, cityId, date) => {
  const url = `${apiURl}/companies/${companyId}/cities/${cityId}/${date}/TripToCities`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetchAndReturn(url, parameters);
};
export { getCitiesRequest, getTripToCitiesRequest };
