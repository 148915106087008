import React from "react";
import { Result, Button } from "antd";
import warningImage from "../../images/add.png";
const EmptyFavoriteDestination = ({ setOpenAddDestinationModal }) => {
  return (
    <>
      <div className="empty-favorite-destination-container">
        <Result
          extra={
            <Button
              type="primary"
              className="custom-button empty-favorite-destination-add-button"
              size="large"
              onClick={() => {
                setOpenAddDestinationModal(true);
              }}
            >
              Добавяне
            </Button>
          }
          icon={
            <img
              className="empty-favorite-destination-image"
              src={warningImage}
              alt="noSchedule"
            />
          }
          title="Няма намерени резултати"
          subTitle={
            <p className="empty-favorite-destination-text">
              Моля, добавете любими дестинации
            </p>
          }
        />
      </div>
    </>
  );
};

export default EmptyFavoriteDestination;
