import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import favoriteImage from "../../images/favorite-destiantion.jpg";
import {
  displayScheduleDateFullNameFormat,
  dateStringToEpoch,
} from "../../utils/utility";
import schedulesActions from "../../redux/schedules/actions";
import destinationActions from "../../redux/destinationForm/actions";
import citiesActions from "../../redux/cities/actions";
import dayjs from "dayjs";
const FavoriteDestinatons = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getTripToCities } = citiesActions;
  const { getSchedules, setUnlockSchedulesPage } = schedulesActions;
  const { setDestinationFrom, setDestinationTo } = destinationActions;
  const { cities } = useSelector((state) => state.citiesReducer);
  const { token, companies } = useSelector((state) => state.mainReducer);
  const { destinationDate } = useSelector((state) => state.destinationReducer);
  const [favoriteDestination] = useState(
    JSON.parse(localStorage.getItem("favoriteDestination")) || []
  );

  const dayjsDate = dayjs(destinationDate.$d);
  // Get epoch timestamp in milliseconds
  const epochTimestamp = dayjsDate.valueOf();
  const epochTimestampInSeconds = Math.floor(epochTimestamp / 1000);

  const findCity = (id) => cities.find((city) => city.id === id);

  const selectSchedule = (destination) => {
    dispatch(setUnlockSchedulesPage(true));
    dispatch(setDestinationFrom(destination.cityFrom));
    setTimeout(() => {
      dispatch(setDestinationTo(destination.cityTo));
    }, 2000);
    dispatch(
      getSchedules(
        token,
        companies[0]?.id,
        destination.cityFrom,
        destination.cityTo,
        dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
      )
    );
    dispatch(
      getTripToCities(
        token,
        companies[0]?.id,
        destination.cityFrom,
        dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
      )
    );
    navigate("/schedules");
  };
  return (
    <>
      <div className="favorite-destination-main-container">
        {favoriteDestination?.length > 0 && (
          <>
            <div className="popular-destination-container">
              <div className="popular-destination-heading-container">
                <p className="popular-destination-sub-heading">
                  {displayScheduleDateFullNameFormat(epochTimestampInSeconds)}
                </p>
                <h1 className="popular-destination-main-heading">
                  Любими дестинации
                </h1>
              </div>
            </div>
            <section className="popular-destination-section">
              <div className="popular-destination-section-left popular-destination-section-area">
                {favoriteDestination?.map((destination, index) => {
                  return (
                    <div
                      key={index}
                      className="popular-destination-card-wrapper"
                    >
                      <div className="popular-destination-card-image-wrapper">
                        <div className="popular-destination-card-image-container">
                          <img
                            style={{ width: "100%", objectFit: "contain" }}
                            className="popular-destination-card-image-item"
                            src={favoriteImage}
                            alt="destinationFrom"
                          />
                        </div>
                      </div>
                      <div className="popular-destination-card-info">
                        <div className="popular-destination-card-text big">
                          {findCity(destination.cityFrom)?.name} -{" "}
                          {findCity(destination.cityTo)?.name}
                        </div>

                        <div className="favorite-destination-text-container">
                          <div>
                            <div className="popular-destination-card-text small">
                              Дата
                              <span className="card-price">
                                {displayScheduleDateFullNameFormat(
                                  epochTimestampInSeconds
                                )}
                              </span>
                            </div>
                          </div>
                          <Button
                            onClick={() => selectSchedule(destination)}
                            type="primary"
                            className="custom-button"
                          >
                            Избор
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default FavoriteDestinatons;
