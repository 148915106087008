import { all, takeEvery, put, call, delay } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "antd";
import {
  getAuthTokenRequest,
  getCompaniesRequest,
} from "../../services/mainService";
import { jwtDecode } from "jwt-decode";

export function* getAuthTokenSaga() {
  const getDelayTime = (expirationTime) => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const timeToExpiration = expirationTime - currentTime;
    const minutesInSeconds = 1 * 60;
    return (timeToExpiration - minutesInSeconds) * 1000;
  };

  try {
    while (true) {
      const response = yield call(getAuthTokenRequest);

      if (response[0] === 200) {
        const token = response[1];
        yield put({
          type: actions.GET_AUTH_TOKEN_SUCCESS,
          token,
        });

        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp;

        const delayTime = getDelayTime(expirationTime);

        if (delayTime > 0) {
          yield delay(delayTime);
        } else {
          continue;
        }
      }
      if (response[0] === 500) {
        notification.error({
          message: "Възникна проблем",
          placement: "top",
        });
      }
    }
  } catch (error) {}
}

export function* getCompaniesSaga({ token }) {
  try {
    const response = yield call(getCompaniesRequest, token);
    if (response[0] === 200) {
      yield put({
        type: actions.GET_COMPANIES_SUCCESS,
        companies: response[1],
      });
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {}
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_AUTH_TOKEN_REQUEST, getAuthTokenSaga),
    yield takeEvery(actions.GET_COMPANIES_REQUEST, getCompaniesSaga),
  ]);
}
