import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SchedulesCard from "../components/Schedules/SchedulesCard";
import NoSchedules from "../components/Schedules/NoSchedules";
import SchedulesLoader from "../components/Schedules/SchedulesLoader";
import { displayScheduleDateFullNameFormat } from "../utils/utility";
import "../components/Schedules/index.css";

const Schedules = () => {
  const { noSchedules, schedulesLoading, schedules } = useSelector(
    (state) => state.schedulesReducer
  );
  const schedulesCardRef = useRef(null);

  useEffect(() => {
    if (
      !schedulesLoading &&
      !noSchedules &&
      schedules.length > 0 &&
      window.innerWidth < 860
    ) {
      schedulesCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [schedulesLoading, noSchedules, schedules]);

  return (
    <>
      {schedulesLoading ? (
        <SchedulesLoader />
      ) : noSchedules ? (
        <NoSchedules />
      ) : (
        <>
          <div
            ref={schedulesCardRef}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
            id="scroll-to-schedule"
          >
            <div className="popular-destination-heading-container">
              <p className="popular-destination-sub-heading">
                {displayScheduleDateFullNameFormat(schedules[0]?.scheduleStart)}
              </p>
              <h1 className="popular-destination-main-heading">
                Свободни курсове
                <span className="underline"></span>
              </h1>
            </div>
          </div>
          <SchedulesCard />
          <div style={{ height: "40px" }}></div>
        </>
      )}
    </>
  );
};

export default Schedules;
