const actions = {
  GET_AUTH_TOKEN_REQUEST: "GET_AUTH_TOKEN_REQUEST",
  GET_AUTH_TOKEN_SUCCESS: "GET_AUTH_TOKEN_SUCCESS",

  GET_COMPANIES_REQUEST: "GET_COMPANIES_REQUEST",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",

  SET_SELECTED_LANGUAGE: "SET_SELECTED_LANGUAGE",

  SET_SHOW_CHOOSE_DESTINATION_TEXT: "SET_SHOW_CHOOSE_DESTINATION_TEXT",

  SET_OPEN_MENU_DRAWER: "SET_OPEN_MENU_DRAWER",

  getAuthToken: () => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_AUTH_TOKEN_REQUEST,
      });
    };
  },

  getCompanies: (token) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_COMPANIES_REQUEST,
        token,
      });
    };
  },

  setSelectedLanguage: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SELECTED_LANGUAGE,
        value,
      });
    };
  },

  setChooseDestinationText: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SHOW_CHOOSE_DESTINATION_TEXT,
        value,
      });
    };
  },

  setOpenMenuDrawer: (value) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_OPEN_MENU_DRAWER,
        value,
      });
    };
  },
};
export default actions;
