import React from "react";
import { Button, notification } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import logo from "../../images/logo.png";
import schedulesActions from "../../redux/schedules/actions";
import {
  epochToNormalDate,
  getBusSeatsCount,
  displayScheduleDateFullNameFormat,
} from "../../utils/utility";

const SchedulesCard = () => {
  const dispatch = useDispatch();
  const { setSchedulesId } = schedulesActions;
  const { selectedLanguage } = useSelector((state) => state.mainReducer);
  const { schedules } = useSelector((state) => state.schedulesReducer);
  const { cities } = useSelector((state) => state.citiesReducer);

  //display only schedules time no full date
  const displayScheduleTime = (schedule) => {
    return epochToNormalDate(schedule).replace(/\d{2}\/\d{2}\/\d{2}/, "");
  };

  const findCityName = (scheduleId) => {
    const name = selectedLanguage === "bg" ? "name" : "nameLat";
    return cities?.find((city) => city.id === scheduleId)?.[name];
  };

  const getScheduleId = (schedule) => {
    dispatch(setSchedulesId(schedule?.kursId));
  };

  // heighest ticket price
  const getSchedulePrice = (schedule) => {
    const priceArray = schedule?.price;
    return priceArray?.reduce((max, current) => {
      return current.price > max.price ? current : max;
    }, priceArray?.[0]?.price);
  };

  const getDiffrencesStarAndEndTime = (startTimeEpoch, endTimeEpoch) => {
    // Convert epoch times from seconds to milliseconds
    const startTimeMs = startTimeEpoch * 1000;
    const endTimeMs = endTimeEpoch * 1000;

    // Create dayjs objects from epoch times (in milliseconds)
    const startTime = dayjs(startTimeMs);
    const endTime = dayjs(endTimeMs);

    // Check if the end time is after the start time

    // Calculate the difference in milliseconds
    const differenceMs = endTime.diff(startTime);

    // Convert milliseconds to hours, minutes, and seconds
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}ч ${minutes}м`;
  };

  return (
    <>
      <div className="schedule-card-container">
        {schedules?.map((schedule) => {
          return (
            <div key={schedule?.kursId} className="schedule-card">
              <div>
                <img className="schedule-card-logo" src={logo} alt="logo" />
              </div>
              <div className="schedule-card-departure">
                <div className="schedule-card-time remove-pointer-events">
                  Тръгване {displayScheduleTime(schedule?.scheduleStart)}
                </div>
                <h2 className="schedule-card-city remove-pointer-events">
                  {findCityName(schedule?.fromCity)}
                </h2>
                <div className="schedule-card-day remove-pointer-events">
                  Свободни места {schedule?.freePlaces} от{" "}
                  {getBusSeatsCount(schedule)}
                </div>
              </div>
              <div className="schedule-card-route">
                <p className="schedule-card-duration remove-pointer-events">
                  {getDiffrencesStarAndEndTime(
                    schedule?.scheduleStart,
                    schedule?.scheduleEnd
                  )}
                </p>
                <div className="schedule-card-line" aria-hidden="true">
                  <div
                    className="schedule-card-line-stop schedule-card-line-start"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="schedule-card-line-stop schedule-card-line-end"
                    aria-hidden="true"
                  ></div>
                </div>
              </div>
              <div className="schedule-card-arrival">
                <div className="schedule-card-time remove-pointer-events">
                  Пристигане {displayScheduleTime(schedule?.scheduleEnd)}
                </div>
                <h2 className="schedule-card-city remove-pointer-events">
                  {findCityName(schedule?.toCity)}
                </h2>
                <div className="schedule-card-day remove-pointer-events">
                  {displayScheduleDateFullNameFormat(schedule.scheduleStart)}
                </div>
              </div>
              <div className="schedule-card-action">
                <p
                  style={{
                    fontSize: getSchedulePrice(schedule)
                      ?.toString()
                      ?.includes(".")
                      ? "35px"
                      : "45px",
                  }}
                  className="schedule-card-price remove-pointer-events"
                >
                  {getSchedulePrice(schedule)?.toString()?.includes(".")
                    ? getSchedulePrice(schedule)?.toFixed(2)
                    : getSchedulePrice(schedule)}
                  <span
                    className="remove-pointer-events"
                    style={{ fontSize: "14px" }}
                  >
                    лв
                  </span>
                </p>
                {schedule?.freePlaces === 0 ? (
                  <Button
                    style={{
                      background: "#d9d9d9",
                      cursor: "not-allowed",
                      color: "#000",
                    }}
                    className="schedule-card-button"
                    size="large"
                    type="primary"
                    onClick={() => {
                      notification.warning({
                        message: "Няма свободни места в автобуса",
                        placement: "top",
                      });
                    }}
                  >
                    <span className="remove-pointer-events">Избор</span>
                  </Button>
                ) : (
                  <Link className="schedules-link" to={`/schedule`}>
                    <Button
                      onClick={() => getScheduleId(schedule)}
                      className="schedule-card-button custom-button"
                      size="large"
                      type="primary"
                    >
                      <span className="remove-pointer-events">Избор</span>
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default SchedulesCard;
