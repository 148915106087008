import actions from "./actions";

const initState = {
  cities: [],
  tripToCity: [],
};

export default function citiesReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.cities,
      };
    case actions.GET_TRIP_TO_CITIES_SUCCESS:
      return {
        ...state,
        tripToCity: action.tripToCity,
      };

    default:
      return state;
  }
}
