import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import destinationActions from "../../redux/destinationForm/actions";
import citiesActions from "../../redux/cities/actions";
import schedulesActions from "../../redux/schedules/actions";
import {
  dateStringToEpoch,
  displayScheduleDateFullNameFormat,
} from "../../utils/utility";
import sofiqImage from "../../images/sofia.jpg";
import plovdivImage from "../../images/plovdiv.jpg";
import smolyanImage from "../../images/smolyan.jpg";
import zlatograd from "../../images/zlatograd.jpg";
import "./index.css";

const PopularDestination = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSchedules, setUnlockSchedulesPage } = schedulesActions;
  const { getTripToCities } = citiesActions;

  const { cities } = useSelector((state) => state.citiesReducer);
  const { token, companies } = useSelector((state) => state.mainReducer);
  const { destinationDate } = useSelector((state) => state.destinationReducer);
  const { setDestinationFrom, setDestinationTo } = destinationActions;

  const dayjsDate = dayjs(destinationDate.$d);

  // Get epoch timestamp in milliseconds
  const epochTimestamp = dayjsDate.valueOf();
  const epochTimestampInSeconds = Math.floor(epochTimestamp / 1000);

  const findCityId = (name) => {
    return cities?.find((city) => city.name === name)?.id;
  };

  const PopularDestinationArray = [
    {
      id: 1,
      fromImage: sofiqImage,
      toImage: smolyanImage,
      formText: "София ЦАГ",
      toText: "Смолян",
      fromId: findCityId("София ЦАГ"),
      toId: findCityId("Смолян"),
    },
    {
      id: 2,
      fromImage: sofiqImage,
      toImage: zlatograd,
      formText: "София ЦАГ",
      toText: "Златоград",
      fromId: findCityId("София ЦАГ"),
      toId: findCityId("Златоград"),
    },
    {
      id: 3,
      fromImage: sofiqImage,
      toImage: plovdivImage,
      formText: "София ЦАГ",
      toText: "Пловдив",
      fromId: findCityId("София ЦАГ"),
      toId: findCityId("Пловдив"),
    },
    {
      id: 4,
      fromImage: smolyanImage,
      toImage: sofiqImage,
      formText: "Смолян",
      toText: "София ЦАГ",
      fromId: findCityId("Смолян"),
      toId: findCityId("София ЦАГ"),
    },
    {
      id: 5,
      toImage: sofiqImage,
      fromImage: zlatograd,
      formText: "Златоград",
      toText: "София ЦАГ",
      fromId: findCityId("Златоград"),
      toId: findCityId("София ЦАГ"),
    },

    {
      id: 6,
      toImage: sofiqImage,
      fromImage: plovdivImage,
      formText: "Пловдив",
      toText: "София ЦАГ",
      fromId: findCityId("Пловдив"),
      toId: findCityId("София ЦАГ"),
    },
  ];

  return (
    <>
      <div className="popular-destination-container">
        <div className="popular-destination-heading-container">
          <p className="popular-destination-sub-heading">
            {displayScheduleDateFullNameFormat(epochTimestampInSeconds)}
          </p>
          <h1 className="popular-destination-main-heading">
            Популярни дестинации
          </h1>
        </div>
      </div>
      <section className="popular-destination-section">
        <div className="popular-destination-section-left popular-destination-section-area">
          {PopularDestinationArray.map((destinaiton) => {
            return (
              <div
                key={destinaiton.id}
                className="popular-destination-card-wrapper"
              >
                <div className="popular-destination-card-image-wrapper">
                  <div className="popular-destination-card-image-container">
                    <img
                      className="popular-destination-card-image-item"
                      src={destinaiton.fromImage}
                      alt="destinationFrom"
                    />
                    <img
                      className="popular-destination-card-image-item"
                      src={destinaiton.toImage}
                      alt="destionationTo"
                    />
                  </div>
                </div>
                <div className="popular-destination-card-info">
                  <div className="popular-destination-card-text big">
                    {destinaiton.formText} - {destinaiton.toText}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div className="popular-destination-card-text small">
                        Дата
                        <span className="card-price">
                          {displayScheduleDateFullNameFormat(
                            epochTimestampInSeconds
                          )}
                        </span>
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        dispatch(setUnlockSchedulesPage(true));

                        dispatch(setDestinationFrom(destinaiton.fromId));
                        setTimeout(() => {
                          dispatch(setDestinationTo(destinaiton.toId));
                        }, 2000);

                        dispatch(
                          getSchedules(
                            token,
                            companies[0]?.id,
                            destinaiton.fromId,
                            destinaiton.toId,
                            dateStringToEpoch(
                              destinationDate.format("DD/MM/YYYY HH:mm")
                            )
                          )
                        );

                        dispatch(
                          getTripToCities(
                            token,
                            companies[0]?.id,
                            destinaiton.fromId,
                            dateStringToEpoch(
                              destinationDate.format("DD/MM/YYYY HH:mm")
                            )
                          )
                        );
                        navigate("/schedules");
                      }}
                      type="primary"
                      className="custom-button"
                    >
                      Избор
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <div style={{ height: "90px" }}></div>
    </>
  );
};

export default PopularDestination;
