import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import mainActions from "./redux/main/actions";
import citiesActions from "./redux/cities/actions";
import { bulgarianLocaleDaysAndMonths } from "./utils/utility";
import Header from "./components/Header/Header";
import Loading from "./components/Loading/Loading";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import MainPage from "./pages/MainPage";
import SchedulesPage from "./pages/SchedulesPage";
import BuyTicketPage from "./pages/BuyTicketPage";
import NotFoundPage from "./pages/NotFoundPage";
import Footer from "./components/Footer/Footer";
import TermsOfUse from "./pages/PolicyPages/TermsOfUse";
import PrivatePolicyPage from "./pages/PolicyPages/PrivatePolicyPage";
import TicketsCheckoutDrawer from "./components/BuyTicket/TicketsCheckout/TicketsCheckoutDrawer";
import TicketCheckoutButton from "./components/BuyTicket/TicketsCheckout/TicketCheckoutButton";
import PopularDestination from "./components/PopularDestination/PopularDestination";
import MenuDrawer from "./components/MenuDrawer/MenuDrawer";
import FavoriteDestionationPage from "./pages/FavoriteDestionationPage";
import FavoriteDestinatons from "./components/FavoriteDestinationSettings/FavoriteDestinatons";
// import RefundTicketPage from "./pages/RefundTicketPage";
import PersonalInfromationPage from "./pages/PersonalInfromationPage";
import SucessTicketsPaymentPage from "./pages/SucessTicketsPaymentPage";
import CancelTicketsPaymentPage from "./pages/CancelTicketsPaymentPage";
import OrderHistoryPage from "./pages/OrderHistoryPage";
import "./index.css";

function App() {
  const dispatch = useDispatch();
  const { getAuthToken, getCompanies } = mainActions;
  const { getCities } = citiesActions;
  const { schedulesId, unlockSchedulesPage } = useSelector(
    (state) => state.schedulesReducer
  );
  const { token, companies } = useSelector((state) => state.mainReducer);
  const { cities } = useSelector((state) => state.citiesReducer);

  // redicret if no secure protocol
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      // Code to run only when not on localhost (i.e., in production)

      //  Disable right-click
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      //  Disable F12 and other developer shortcuts
      document.addEventListener("keydown", (e) => {
        if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
          e.preventDefault();
        }
      });
    }
  }, []);

  // redicret if no secure protocol
  useEffect(() => {
    if (
      window.location.protocol === "http:" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.href = window.location.href.replace("http:", "https:");
    }
  }, []);

  useEffect(() => {
    if (token?.length === 0) {
      dispatch(getAuthToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token?.length > 0 && companies?.length === 0) {
      dispatch(getCompanies(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (companies.length > 0 && cities.length === 0) {
      dispatch(getCities(token, companies[0]?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    // Register the Bulgarian locale with Day.js
    dayjs.locale(bulgarianLocaleDaysAndMonths);
  }, []);

  const ProtectiveBussPage = () => {
    return schedulesId > 0 ? <BuyTicketPage /> : <Navigate to="/" replace />;
  };

  const ProtectiveSchedulesPage = () => {
    return unlockSchedulesPage ? (
      <SchedulesPage />
    ) : (
      <Navigate to="/" replace />
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <MainPage />
          <FavoriteDestinatons />
          <PopularDestination />
          {/* <ChooseDestinationText /> */}
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    {
      path: "/schedules",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <MainPage />
          <ProtectiveSchedulesPage />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },

    {
      path: "/schedule",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <ProtectiveBussPage />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    {
      path: "/favorite-destination",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <FavoriteDestionationPage />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    // {
    //   path: "/refund-ticket",
    //   element: (
    //     <>
    //       <MenuDrawer />
    //       <Header />
    //       <ScrollToTop />
    //       <RefundTicketPage />
    //       <TicketCheckoutButton />
    //       <TicketsCheckoutDrawer />
    //       <Footer />
    //     </>
    //   ),
    //   children: [
    //     {
    //       path: "cart",
    //     },
    //   ],
    // },
    {
      path: "/personal-information",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <PersonalInfromationPage />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    {
      path: "/:myPosCompanyId/payment/:myPosOrderId/success",
      element: (
        <>
          <MenuDrawer />
          <SucessTicketsPaymentPage />
        </>
      ),
    },
    {
      path: "/:myPosCompanyId/payment/:myPosOrderId/cancel",
      element: (
        <>
          <MenuDrawer />
          <CancelTicketsPaymentPage />
        </>
      ),
    },
    {
      path: "/terms-of-use",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <TermsOfUse />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    {
      path: "/private-policy",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <ScrollToTop />
          <PrivatePolicyPage />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    {
      path: "/order-history",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <OrderHistoryPage />
          <TicketCheckoutButton />
          <TicketsCheckoutDrawer />
          <Footer />
        </>
      ),
      children: [
        {
          path: "cart",
        },
      ],
    },
    {
      path: "*",
      element: (
        <>
          <MenuDrawer />
          <Header />
          <NotFoundPage />
          <Footer />
        </>
      ),
    },
  ]);
  if (!token || companies.length === 0 || cities.length === 0) {
    return <Loading />;
  }
  return <RouterProvider router={router} />;
}

export default App;
