import React from "react";
import DestinationHeader from "../components/DestinationInputs/DestinationHeader";
import DestinationForm from "../components/DestinationInputs/DestinationForm";
import "../components/DestinationInputs/index.css";
const MainPage = () => {
  return (
    <>
      <div className="main-page-banner">
        <DestinationHeader />
        <DestinationForm />
      </div>
    </>
  );
};

export default MainPage;
