import React, { useState } from "react";
import { Select, DatePicker, Button, message, Result, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { dateStringToEpoch } from "../../utils/utility";
import citiesActions from "../../redux/cities/actions";
import schedulesActions from "../../redux/schedules/actions";
import destinationActions from "../../redux/destinationForm/actions";
import mainActions from "../../redux/main/actions";
import {
  bulgarianLocaleDaysAndMonths,
  bulgarianLocalCalandarText,
} from "../../utils/utility";
import "./index.css";
const { Option } = Select;

const DestinationForm = () => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setChooseDestinationText } = mainActions;
  const { getTripToCities } = citiesActions;
  const { getSchedules, setUnlockSchedulesPage } = schedulesActions;
  const { setDestinationFrom, setDestinationTo, setDestinationDate } =
    destinationActions;
  const { token, companies, selectedLanguage } = useSelector(
    (state) => state.mainReducer
  );
  const { cities, tripToCity } = useSelector((state) => state.citiesReducer);
  const { destinationFrom, destinationTo, destinationDate } = useSelector(
    (state) => state.destinationReducer
  );

  const [checkEmptyDestinationInput, setCheckEmptyDestinationInput] =
    useState(false);
  const [fillEmptyMessageApi, emptyMessageHolder] = message.useMessage();
  const [startPlusButtonAnimation, setStartPlusButtonAnimation] =
    useState(false);
  const [startMinusButtonAnimation, setStartMinusButtonAnimation] =
    useState(false);

  const [searchValueFrom, setSearchValueFrom] = useState("");
  const [searchValueTo, setSearchValueTo] = useState("");

  const displayEmptyInputsError = () => {
    fillEmptyMessageApi.open({
      type: "error",
      content: (
        <span className="remove-pointer-events">Попълнете празните полета</span>
      ),
    });
  };

  const getSchedulesRequest = () => {
    setCheckEmptyDestinationInput(true);
    if (destinationDate && destinationFrom && destinationTo) {
      dispatch(
        getSchedules(
          token,
          companies[0].id,
          destinationFrom,
          destinationTo,
          dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
        )
      );
      dispatch(setUnlockSchedulesPage(true));
      dispatch(setChooseDestinationText(false));
      if (pathname !== "/schedules") {
        navigate("/schedules");
      }
      //Scroll to Schedules in mobile view
      if (window.innerWidth < 820) {
        setTimeout(() => {
          const scrollToSchedule =
            document.getElementById("scroll-to-schedule");
          const scrollToNoSchedule =
            document.getElementById("scrollToNoSchedule");

          if (scrollToSchedule) {
            // 👇 Will scroll smoothly to the top of the next section
            scrollToSchedule.scrollIntoView({ behavior: "smooth" });
          }
          if (scrollToNoSchedule) {
            // 👇 Will scroll smoothly to the top of the next section
            scrollToNoSchedule.scrollIntoView({ behavior: "smooth" });
          }
        }, 500);
      }
    } else {
      displayEmptyInputsError();
    }
  };

  const citiesOptions = (array) => {
    return array.map((city) => {
      return {
        value: city.id,
        label: selectedLanguage === "bg" ? city?.name : city?.nameLat,
      };
    });
  };

  const getFromDestinationValue = (value) => {
    dispatch(setDestinationFrom(value));
  };

  const getToDestinationValue = (value) => {
    dispatch(setDestinationTo(value));
  };

  const getDestinationDate = (date, dateString) => {
    dispatch(setDestinationDate(date));
    if (destinationFrom) {
      dispatch(
        getTripToCities(
          token,
          companies[0]?.id,
          destinationFrom,
          dateStringToEpoch(date.format("DD/MM/YYYY HH:mm"))
        )
      );
    }
  };

  const incrementDate = () => {
    setStartPlusButtonAnimation(true);

    const plusOneDay = destinationDate.add(1, "day");
    dispatch(setDestinationDate(plusOneDay));
    if (destinationFrom) {
      dispatch(
        getTripToCities(
          token,
          companies[0]?.id,
          destinationFrom,
          dateStringToEpoch(plusOneDay.format("DD/MM/YYYY HH:mm"))
        )
      );
    }
  };
  const animationEndPlusButton = () => {
    setStartPlusButtonAnimation(false);
  };

  const decrementDate = () => {
    setStartMinusButtonAnimation(true);
    const minusOneDay = destinationDate.subtract(1, "day");
    dispatch(setDestinationDate(minusOneDay));
    if (destinationFrom) {
      dispatch(
        getTripToCities(
          token,
          companies[0]?.id,
          destinationFrom,
          dateStringToEpoch(minusOneDay.format("DD/MM/YYYY HH:mm"))
        )
      );
    }
  };

  const animationEndMinusButton = () => {
    setStartMinusButtonAnimation(false);
  };

  const getTripToCity = (value) => {
    getFromDestinationValue(value);
    if (value) {
      dispatch(
        getTripToCities(
          token,
          companies[0]?.id,
          value,
          dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
        )
      );
    }
  };
  const handleGetSearchFromValue = (value) => {
    setSearchValueFrom(value);
  };

  const handleGetSearchToValue = (value) => {
    setSearchValueTo(value);
  };

  const searchDestinationFrom = (searchedValue, getSearchedValue) => (
    <Input
      variant="borderless"
      placeholder="Търсене"
      value={searchedValue}
      onChange={(e) => getSearchedValue(e.target.value)}
    />
  );

  const filteredCities = (cityArray, searchedValue) => {
    return cityArray.filter((city) =>
      selectedLanguage === "bg"
        ? city?.name?.toLowerCase()?.includes(searchedValue?.toLowerCase())
        : city?.nameLat?.toLowerCase()?.includes(searchedValue?.toLowerCase())
    );
  };

  return (
    <>
      {emptyMessageHolder}
      <div className="destination-form-container">
        <div className="destination-form-date-picker-container">
          <Button
            className={
              startMinusButtonAnimation
                ? "destination-form-change-date-button-minus clicked"
                : "destination-form-change-date-button-minus"
            }
            size="large"
            onClick={decrementDate}
            disabled={destinationDate <= dayjs() ? true : false}
            onAnimationEnd={animationEndMinusButton}
          >
            <MinusOutlined className="destination-form-change-date-button-icon" />
          </Button>
          <div className="destination-form-datepicker-container">
            <DatePicker
              allowClear={false}
              inputReadOnly={true}
              minDate={dayjs()}
              value={destinationDate}
              showNow={false}
              className="destination-form-items-datepicker"
              size="large"
              format="DD/MM/YYYY"
              onChange={getDestinationDate}
              placeholder="Дата"
              locale={{
                lang: bulgarianLocalCalandarText,
                dayjsLocale: bulgarianLocaleDaysAndMonths,
              }}
            />
          </div>
          <Button
            className={
              startPlusButtonAnimation
                ? "destination-form-change-date-button-plus clicked"
                : "destination-form-change-date-button-plus"
            }
            size="large"
            onClick={incrementDate}
            onAnimationEnd={animationEndPlusButton}
          >
            <PlusOutlined className="destination-form-change-date-button-icon" />
          </Button>
        </div>
        <Select
          dropdownRender={(menu) => (
            <div>
              {searchDestinationFrom(searchValueFrom, handleGetSearchFromValue)}
              {menu}
            </div>
          )}
          value={destinationFrom || undefined}
          status={
            checkEmptyDestinationInput &&
            (destinationFrom?.length === 0 || destinationFrom === undefined) &&
            "error"
          }
          size="large"
          className="destination-form-items"
          onChange={(value) => {
            getTripToCity(value);
          }}
          placeholder="От къде"
          notFoundContent={
            <Result
              icon={null}
              title={
                <p className="destination-form-select-empty-title remove-pointer-events">
                  Няма намерени градове
                </p>
              }
            />
          }
        >
          {citiesOptions(filteredCities(cities, searchValueFrom)).map(
            (option) => (
              <Option
                key={option.value}
                value={option.value}
                label={option.label}
              >
                <span className="destination-form-select-option remove-pointer-events">
                  {option.label}
                </span>
              </Option>
            )
          )}
        </Select>

        <Select
          value={destinationTo || undefined}
          dropdownRender={(menu) => (
            <div>
              {searchDestinationFrom(searchValueTo, handleGetSearchToValue)}
              {menu}
            </div>
          )}
          status={
            checkEmptyDestinationInput &&
            (destinationTo?.length === 0 || destinationTo === undefined) &&
            "error"
          }
          size="large"
          className="destination-form-items"
          onChange={getToDestinationValue}
          placeholder="До къде"
          notFoundContent={
            <Result
              icon={null}
              title={
                <p className="destination-form-select-empty-title remove-pointer-events">
                  Няма намерени градове
                </p>
              }
            />
          }
        >
          {citiesOptions(filteredCities(tripToCity, searchValueTo)).map(
            (option) => (
              <Option
                key={option.value}
                value={option.value}
                label={option.label}
              >
                <span className="destination-form-select-option remove-pointer-events">
                  {option.label}
                </span>
              </Option>
            )
          )}
        </Select>
        <Button
          icon={<SearchOutlined />}
          size="large"
          type="primary"
          className="destination-form-button custom-button"
          onClick={() => {
            getSchedulesRequest();
          }}
        >
          <span className="remove-pointer-events">Търсене</span>
        </Button>
      </div>
    </>
  );
};

export default DestinationForm;
