import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Button, Carousel, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { epochToNormalDate, dateStringToEpoch } from "../../../utils/utility";
import logo from "../../../images/logo.png";
import arrow from "../../../images/right-arrow.png";
import ticketActions from "../../../redux/ticket/actions";
import schedulesActions from "../../../redux/schedules/actions";
import ticketImage from "../../../images/ticket3.png";
import cancelledImage from "../../../images/cancelled.png";
import EmptyCart from "./EmptyCart";
import MyPos from "../MyPos";
import { decryptData, encryptData } from "../../../utils/utility";
import "./index.css";

const TicketsCheckoutDrawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    addTicketsToCart,
    myposReservedTicket,
    cancelTicketReservation,
    setOpenCheckoutDrawer,
    setTicketsExpired,
  } = ticketActions;
  const { getSchedules } = schedulesActions;
  const { companies, token } = useSelector((state) => state.mainReducer);
  const { cities } = useSelector((state) => state.citiesReducer);
  const {
    checkoutTickets,
    openCheckoutDrawer,
    myposPaymentInformationLoading,
    ticketsExpired,
  } = useSelector((state) => state.ticketReducer);
  const { destinationFrom, destinationTo, destinationDate } = useSelector(
    (state) => state.destinationReducer
  );
  const [reservedTicketsWithExpiry, setReservedTicketsWithExpiry] = useState(
    []
  );
  const [timeRemaining, setTimeRemaining] = useState(null);

  const localStorageCart = decryptData(localStorage.getItem("cart")) || [];
  const localStorageReservedTickets =
    decryptData(localStorage.getItem("reservedTickets")) || [];

  const getCitiesNames = (cartTicketCityId) => {
    return cities.find((city) => city?.id === cartTicketCityId)?.name;
  };

  const getTotalTicketsAmount = localStorageCart?.reduce(
    (accumulator, product) => {
      return accumulator + product.price;
    },
    0
  );

  const onCloseCheckoutDrawer = () => {
    dispatch(setOpenCheckoutDrawer(false));
    navigate(window.location.pathname.replace("/cart", ""));
  };

  const buyTicket = () => {
    dispatch(
      myposReservedTicket(
        token,
        companies[0]?.id,
        localStorageReservedTickets?.map((ticket) => ({
          article: ticket.namePrice,
          quantity: 1,
          price: ticket.price,
          amount: ticket.price,
          currency: 0,
          reserveId: ticket.id,
        })),
        "bg"
      )
    );
  };

  const removeTicketFromCart = (kursId, placeNumber) => {
    const updatedCart = checkoutTickets.filter(
      (item) => !(item.kursId === kursId && item.placeNumber === placeNumber)
    );
    dispatch(addTicketsToCart(updatedCart));

    const updatedLocalStorageCart = localStorageCart.filter(
      (item) => !(item.kursId === kursId && item.placeNumber === placeNumber)
    );
    localStorage.setItem("cart", encryptData(updatedLocalStorageCart));

    const event = new Event("cartUpdated");
    window.dispatchEvent(event);

    const updatedReservedTickets = localStorageReservedTickets.filter(
      (item) => !(item.kursId === kursId && item.placeNumber === placeNumber)
    );
    localStorage.setItem(
      "reservedTickets",
      encryptData(updatedReservedTickets)
    );
  };

  useEffect(() => {
    setReservedTicketsWithExpiry(localStorageReservedTickets || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(localStorageReservedTickets)]);

  useEffect(() => {
    const checkExpirations = () => {
      const expirationTime = localStorage.getItem("ticketExpirationTime");
      if (expirationTime) {
        const remainingTime = expirationTime - new Date().getTime();
        if (remainingTime > 0) {
          setTimeRemaining(remainingTime);
        } else {
          setTimeRemaining(0);
          if (companies.length > 0) {
            reservedTicketsWithExpiry.forEach((ticket) => {
              dispatch(
                cancelTicketReservation(token, companies[0]?.id, ticket.id)
              );
            });

            setTimeout(() => {
              dispatch(
                getSchedules(
                  token,
                  companies[0]?.id,
                  destinationFrom,
                  destinationTo,
                  dateStringToEpoch(destinationDate.format("DD/MM/YYYY HH:mm"))
                )
              );
            }, 1000);

            dispatch(setTicketsExpired(true));
            localStorage.removeItem("cart");
            dispatch(addTicketsToCart([]));
            localStorage.removeItem("reservedTickets");
            const event = new Event("cartUpdated");
            window.dispatchEvent(event);
            localStorage.removeItem("ticketExpirationTime");
            setReservedTicketsWithExpiry([]);
          }
        }
      }
    };

    const intervalId = setInterval(checkExpirations, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservedTicketsWithExpiry, companies]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (window.location.pathname.includes("/cart")) {
        dispatch(setOpenCheckoutDrawer(true));
      } else {
        dispatch(setOpenCheckoutDrawer(false));
      }
    };

    handleRouteChange();

    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatTimeRemaining = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <>
      <MyPos />
      <Drawer
        styles={{
          body: {
            padding: window.innerWidth > 760 ? "20px" : "10px",
            paddingBottom: "20px",
          },
        }}
        width={window.innerWidth > 760 ? "450px" : "100%"}
        title={`Билети (${localStorageCart.length})`}
        onClose={onCloseCheckoutDrawer}
        open={openCheckoutDrawer}
        footer={
          localStorageCart.length > 0 ? (
            <>
              <Button
                disabled={
                  myposPaymentInformationLoading ||
                  ticketsExpired ||
                  getTotalTicketsAmount === 0
                }
                className={` ticket-drawer-pay-button ${
                  getTotalTicketsAmount === 0
                    ? "disabled-pay-button"
                    : "custom-button"
                }`}
                loading={myposPaymentInformationLoading}
                size="large"
                type="primary"
                onClick={() =>
                  getTotalTicketsAmount > 0 &&
                  !myposPaymentInformationLoading &&
                  buyTicket()
                }
              >
                Плащане {getTotalTicketsAmount.toFixed(2)} лв
              </Button>
            </>
          ) : (
            <Button
              className="custom-button ticket-drawer-pay-button"
              size="large"
              type="primary"
              onClick={() => dispatch(setOpenCheckoutDrawer(false))}
            >
              Назад
            </Button>
          )
        }
      >
        {localStorageCart.length > 0 ? (
          <>
            <Carousel className="tickets-card-carousel" draggable>
              <div>
                <div className="carousel-card">
                  <div className="carousel-card-image">
                    <img src={ticketImage} alt="CardImage1" />
                  </div>
                  <div className="carousel-card-content">
                    <h2>Важно</h2>
                    <p>
                      След успешно закупуване на билет, системата ще изпрати
                      билетите на посочените от клиента имейл адреси
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="carousel-card">
                  <div className="carousel-card-image">
                    <img src={cancelledImage} alt="CardImage2" />
                  </div>
                  <div className="carousel-card-content">
                    <h2>Отменено пътуване</h2>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Id magnam quasi reprehenderit a odio. Aspernatur odio
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>
            <Alert
              description={`Билетите ще са валидни в кошницата за още ${formatTimeRemaining(
                timeRemaining
              )} минути.`}
              showIcon
              style={{ marginBottom: "20px" }}
            />
            {localStorageCart?.map((ticket, index) => (
              <div className="ticket-card" key={index}>
                <div className="ticket-item">
                  <div className="ticket-item-header">
                    <img src={logo} alt="ticketLogo" className="logo" />
                    <div className="ticket-item-e-ticket-mark">
                      <small>{ticket.ticketName}</small>
                    </div>
                  </div>

                  <section className="ticket-item-cities-container">
                    <div className="ticket-item-city">
                      <h3>{getCitiesNames(ticket?.fromCity)}</h3>
                    </div>
                    <div className="ticket-item-city">
                      <img
                        src={arrow}
                        alt="arrow"
                        className="destination-arrow"
                      />
                    </div>
                    <div className="ticket-item-city">
                      <h3>{getCitiesNames(ticket?.toCity)}</h3>
                    </div>
                  </section>
                  {getTotalTicketsAmount === 0 && (
                    <Alert
                      description={`${ticket.ticketName} трябва да пътуват с придружител`}
                      type="warning"
                      showIcon
                      className="ticket-drawer-under-age-alert"
                    />
                  )}
                  <section className="ticket-item-information">
                    <div className="ticket-item-information-item-container">
                      <div className="box">
                        <small>Тръгване</small>
                        <strong>
                          {epochToNormalDate(ticket.scheduleStart)}
                        </strong>
                      </div>
                      <div className="box">
                        <small>Име</small>
                        <strong>{ticket.forPerson}</strong>
                      </div>
                      <div className="box">
                        <small>Пристигане</small>
                        <strong>{epochToNormalDate(ticket.scheduleEnd)}</strong>
                      </div>
                      <div className="box">
                        <small>Място</small>
                        <strong>{ticket.placeNumber}</strong>
                      </div>
                      <div className="box">
                        <small>Телефон</small>
                        <strong>{ticket.phone}</strong>
                      </div>
                      <div className="box">
                        <small>Сектор</small>
                        <strong>{ticket.sector}</strong>
                      </div>
                    </div>
                  </section>
                  <section className="strap">
                    <div className="box">
                      <div className="passenger">
                        <small>Имейл</small>
                        <strong>{ticket.email}</strong>
                      </div>
                      <div className="ticket-card-action-container">
                        <div className="date">
                          <small>Цена</small>
                          <strong>{ticket?.price?.toFixed(2)} лв</strong>
                        </div>
                        <Button
                          onClick={() => {
                            dispatch(
                              cancelTicketReservation(
                                token,
                                companies[0]?.id,
                                localStorageReservedTickets?.find(
                                  (item) =>
                                    item.kursId === ticket.kursId &&
                                    item.placeNumber === ticket.placeNumber
                                )?.id
                              )
                            );
                            removeTicketFromCart(
                              ticket.kursId,
                              ticket.placeNumber
                            );
                            setTimeout(() => {
                              dispatch(
                                getSchedules(
                                  token,
                                  companies[0]?.id,
                                  destinationFrom,
                                  destinationTo,
                                  dateStringToEpoch(
                                    destinationDate.format("DD/MM/YYYY HH:mm")
                                  )
                                )
                              );
                            }, 1000);

                            if (localStorageCart?.length === 1) {
                              localStorage.removeItem("ticketExpirationTime");
                            }
                          }}
                          className="ticket-item-remove-button"
                          danger
                        >
                          Премахване
                        </Button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <EmptyCart />
            {ticketsExpired && (
              <Alert
                description="Билетите ви са изтекли. Моля, добавете ги отново, за да завършите покупката."
                type="error"
                showIcon
              />
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

export default TicketsCheckoutDrawer;
