import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "antd";
import {
  getSchedulesRequest,
  getScheduleStopsRequest,
} from "../../services/schedulesServices";

export function* getSchedulesSaga({ token, companyId, from, to, date }) {
  try {
    const response = yield call(
      getSchedulesRequest,
      token,
      companyId,
      from,
      to,
      date
    );
    if (response[0] === 200) {
      yield put({
        type: actions.GET_SCHEDULES_SUCCESS,
        schedules: response[1],
      });
      yield put({
        type: actions.SCHEDULES_LOADER,
        noSchedules: false,
      });
    } else {
      yield put({
        type: actions.GET_SCHEDULES_SUCCESS,
        schedules: [],
      });
      yield put({
        type: actions.SCHEDULES_LOADER,
        noSchedules: true,
      });
    }
    if (response[0] === 204) {
      yield put({
        type: actions.GET_SCHEDULES_SUCCESS,
        schedules: [],
      });
      yield put({
        type: actions.SCHEDULES_LOADER,
        noSchedules: true,
      });
    }
    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
  }
}

export function* getScheduleStopsSaga({ token, companyId, kursId }) {
  try {
    yield put({
      type: actions.SCHEDULE_STOPS_LOADING,
      schedulesStopsLoading: true,
    });

    const response = yield call(
      getScheduleStopsRequest,
      token,
      companyId,
      kursId
    );

    if (response[0] === 200) {
      yield put({
        type: actions.GET_SCHEDULE_STOPS_SUCCESS,
        scheduleStops: response[1],
      });
      yield put({
        type: actions.SET_OPEN_SCHEDULE_INFO_MODAL,
        value: true,
      });
      yield put({
        type: actions.SCHEDULE_STOPS_LOADING,
        schedulesStopsLoading: false,
      });
    }

    if (response[0] === 500) {
      notification.error({
        message: "Възникна проблем",
        placement: "top",
      });

      yield put({
        type: actions.SCHEDULE_STOPS_LOADING,
        schedulesStopsLoading: false,
      });
    }
  } catch (error) {
    notification.error({
      message: "Сесията ви е изтекла, моля рестартирайте приложението",
      placement: "top",
    });
    yield put({
      type: actions.SCHEDULE_STOPS_LOADING,
      schedulesStopsLoading: false,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_SCHEDULES_REQUEST, getSchedulesSaga),
    yield takeEvery(actions.GET_SCHEDULE_STOPS_REQUEST, getScheduleStopsSaga),
  ]);
}
