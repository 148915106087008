import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Result } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import ticketsActions from "../../../redux/ticket/actions";
import { epochToNormalDate } from "../../../utils/utility";
import logo from "../../../images/logo.png";
import ExpireSuccessTickets from "./ExpireSuccessTickets";

const SuccessTicketsPayment = () => {
  const dispatch = useDispatch();
  const { token, companies } = useSelector((state) => state.mainReducer);
  const { sendSuccessTicketPayment, getCreatedTickets, getTicketPDF } =
    ticketsActions;
  const { myPosCompanyId, myPosOrderId } = useParams();
  const { successMyPosTickets, createdTicktes } = useSelector(
    (state) => state.ticketReducer
  );

  useEffect(() => {
    if (token.length > 0) {
      dispatch(sendSuccessTicketPayment(token, myPosCompanyId, myPosOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    localStorage.removeItem("cart");
    localStorage.removeItem("reservedTickets");
    localStorage.removeItem("ticketExpirationTime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const successMyPosTicketsValuesToSting = successMyPosTickets.map((ticket) =>
      ticket.toString()
    );
    if (
      token.length > 0 &&
      companies.length > 0 &&
      successMyPosTickets.length > 0
    ) {
      dispatch(
        getCreatedTickets(
          token,
          companies[0]?.id,
          successMyPosTicketsValuesToSting
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, companies, successMyPosTickets]);

  useEffect(() => {
    const savedCreatedTickets = JSON.parse(
      localStorage.getItem("createdTickets") || "[]"
    );

    const newTickets = createdTicktes.filter(
      (ticket) =>
        !savedCreatedTickets.some(
          (savedTicket) => savedTicket.ticketNumber === ticket.ticketNumber
        )
    );

    if (newTickets.length > 0) {
      const updatedTickets = [...savedCreatedTickets, ...newTickets];

      // Retain only the last 10 tickets
      const latestTickets = updatedTickets.slice(-10);

      localStorage.setItem("createdTickets", JSON.stringify(latestTickets));
    }
  }, [createdTicktes]);

  return (
    <>
      {successMyPosTickets.length > 0 ? (
        <>
          <Result
            status="success"
            title="Успешно плащане"
            subTitle={
              <p className="success-ticket-payment-result-subtitle">
                След успешно закупуване на билет, системата ще изпрати билетите
                на посочените от клиента имейл адреси.
              </p>
            }
            extra={[
              <a href="/" key="1">
                <Button
                  className="custom-button success-ticket-payment-result-back-button"
                  type="primary"
                >
                  Начало
                </Button>
              </a>,
            ]}
            className="success-ticket-payment-result"
          />
          <div className="success-ticket-payment-result-container">
            {createdTicktes?.map((ticket) => {
              return (
                <div
                  key={ticket.ticketNumber}
                  className="success-ticket-payment-ticket-container"
                >
                  <div className="success-ticket-payment-ticket-item">
                    <img
                      src={logo}
                      style={{ width: "100px", marginBottom: "10px" }}
                      alt="logo"
                    />
                    <p style={{ color: "#b3b3b3" }}>Е-Билет</p>
                  </div>
                  <div className="success-ticket-payment-ticket-item">
                    <span>Дестинация</span>
                    <span>
                      {ticket.fromName} - {ticket.toName}
                    </span>
                  </div>
                  <div className="success-ticket-payment-ticket-item">
                    <span>Дата и час</span>
                    <span>{epochToNormalDate(ticket.startTime)}</span>
                  </div>
                  <div className="success-ticket-payment-ticket-item">
                    <span>Име</span>
                    <span>{ticket.forPerson}</span>
                  </div>
                  <div className="success-ticket-payment-ticket-item">
                    <span>Имейл</span>
                    <span>{ticket.email}</span>
                  </div>
                  <div className="success-ticket-payment-ticket-item">
                    <span>Място</span>
                    <span>{ticket.place}</span>
                  </div>
                  <div className="success-ticket-payment-ticket-item">
                    <span>Цена</span>
                    <span>{ticket.price.toFixed(2)} лв</span>
                  </div>
                  <Button
                    type="text"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      background: "#e6e6e6",
                    }}
                    onClick={() => {
                      dispatch(
                        getTicketPDF(
                          token,
                          companies[0]?.id,
                          ticket.ticketNumber
                        )
                      );
                    }}
                    icon={<FilePdfOutlined />}
                    size="large"
                  >
                    Изтегли билет
                  </Button>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <ExpireSuccessTickets />
      )}
    </>
  );
};

export default SuccessTicketsPayment;
