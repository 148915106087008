import actions from "./actions";
import dayjs from "dayjs";
const initState = {
  destinationFrom: "",
  destinationTo: "",
  destinationDate: dayjs(),
};

export default function destinationReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.SET_DESTINATION_FROM:
      return {
        ...state,
        destinationFrom: action.value,
      };

    case actions.SET_DESTINATION_TO:
      return {
        ...state,
        destinationTo: action.value,
      };
    case actions.SET_DESTINATION_DATE:
      return {
        ...state,
        destinationDate: action.value,
      };
    default:
      return state;
  }
}
