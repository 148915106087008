import "./index.css";
import { Seat } from "./Seat";
// import { useSelector } from "react-redux";
// import { SeatIcon } from "./SeatIcon";
// import { getBusSeatsCount } from "../../../../utils/utility";

export const SeatMap = ({
  layout,
  handleSeatClick,
  selectedSeatIds,
  seatIcon,
  selectedSeatIcon,
  reservedSeatIcon,
  emptySpace,
  checkEmptyInput,
  selectedSeat,
  // seatMapLegendPostion = "TOP",
}) => {
  // const { schedules, schedulesId } = useSelector(
  //   (state) => state.schedulesReducer
  // );

  const renderSeatAreas = (grids, maxSeatPerRow) => {
    return grids.map((grid, gridIdx) =>
      renderSeatGrids(gridIdx, grid.seats, maxSeatPerRow)
    );
  };

  const renderSeatGrids = (gridIdx, seats, maxSeatPerRow) => {
    const gridRows = new Map();

    for (let i = 1; i <= maxSeatPerRow; i++) {
      gridRows.set(i, <span key={i}>&nbsp;</span>);
    }

    seats.forEach((seat, seatIdx) => {
      gridRows.set(
        seat.gridSeatNum,
        <Seat
          key={seatIdx}
          seat={seat}
          selectedSeatIds={selectedSeatIds}
          handleSeatClick={handleSeatClick}
          seatIcon={seatIcon}
          selectedSeatIcon={selectedSeatIcon}
          reservedSeatIcon={reservedSeatIcon}
          emptySpace={emptySpace}
        />
      );
    });

    return (
      <div
        key={gridIdx}
        className="grid-container"
        style={{ gridTemplateColumns: "60px ".repeat(maxSeatPerRow) }}
      >
        {Array.from(gridRows.values())}
      </div>
    );
  };

  // const getExactSchedule = schedules?.find(
  //   (schedule) => schedule.kursId === schedulesId
  // );

  // const getSeatMapPosition = (position = "BOTTOM") => {
  //   let flexDirection;

  //   switch (position) {
  //     case "TOP":
  //       flexDirection = "column-reverse";
  //       break;
  //     case "RIGHT":
  //       flexDirection = "row";
  //       break;
  //     case "BOTTOM":
  //       flexDirection = "column";
  //       break;
  //     case "LEFT":
  //       flexDirection = "row-reverse";
  //       break;
  //     default:
  //       flexDirection = "row";
  //       break;
  //   }

  //   return flexDirection;
  // };

  // const isLeftRightSeatLegend = (position) => {
  //   return ["LEFT", "RIGHT"].includes(position);
  // };
  return (
    <
      // style={{
      //   display: "flex",
      //   flexDirection: getSeatMapPosition(seatMapLegendPostion),
      // }}
    >
      <div className="seat-map-container">
        <p className="seat-map-heading remove-pointer-events">Място</p>
        {layout.areas.map((area, index) => (
          <div
            className={`area-name ${
              checkEmptyInput &&
              selectedSeat === null &&
              "empty-tickets-and-seats"
            }`}
            key={`area-${index}`}
          >
            {renderSeatAreas(area.grids, layout.maxSeatPerRow)}
            {area.areaName && <h3 className="text-center">{area.areaName}</h3>}
          </div>
        ))}
      </div>
      {/* <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: isLeftRightSeatLegend(seatMapLegendPostion)
            ? "column"
            : "row",
          justifyContent: isLeftRightSeatLegend(seatMapLegendPostion)
            ? "center"
            : "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {!reservedSeatIcon && <SeatIcon state="reserved" />}
          {reservedSeatIcon}
          Reserved
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!seatIcon && <SeatIcon />}
          {seatIcon}
          Available
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!selectedSeatIcon && <SeatIcon state="selected" />}
          {selectedSeatIcon}
          Selected
        </div>
      </div> */}
    </>
  );
};
