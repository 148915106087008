import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import ticketsActions from "../../redux/ticket/actions";
import logo from "../../images/logo.png";
import arrowImage from "../../images/right-arrow.png";
import { epochToNormalDate } from "../../utils/utility";
import "./index.css";
const Cell = (props) => {
  const {
    tickets: {
      ticketNumber,
      place,
      fromName,
      toName,
      typeName,
      startTime,
      endTime,
      email,
      forPerson,
      sektor,
      price,
    },
  } = props;
  const dispatch = useDispatch();
  const [active, handleActive] = useState(false);
  const { token, companies } = useSelector((state) => state.mainReducer);
  const { getTicketPDF } = ticketsActions;

  return (
    <div
      className="order-history-card-container"
      style={{
        height: active ? `300px` : `100px`,
      }}
    >
      <div
        className="order-history-card-first-column"
        onClick={() => {
          handleActive(!active);
        }}
      >
        <div className="order-history-card-row">
          <div
            className="order-history-card-row-text"
            style={{ fontWeight: "bold", color: "rgb(13, 28, 83)" }}
          >
            От
          </div>
          {fromName}
        </div>
        <div
          className="order-history-card-row"
          style={{
            marginTop: "15px",
          }}
        >
          <img alt="arrow" style={{ width: "30px" }} src={arrowImage} />
        </div>
        <div className="order-history-card-row">
          <div
            className="order-history-card-row-text"
            style={{ fontWeight: "bold", color: "rgb(13, 28, 83)" }}
          >
            До
          </div>
          {toName}
        </div>
      </div>
      <div
        className="order-history-card-row-detail-first"
        style={{
          transform: active
            ? `rotate3d(1, 0, 0, -180deg)`
            : `rotate3d(1, 0, 0, 0deg)`,
          transitionDelay: active ? "0s" : "0.3s",
        }}
      >
        <div
          className="order-history-card-row-detail-first-top"
          onClick={() => {
            handleActive(!active);
          }}
        >
          <img alt="logo" className="order-history-card-logo" src={logo} />

          <div className="order-history-card-row-detail-time-container">
            <div className="order-history-card-row-detail-date">
              {fromName}
              <div className="order-history-card-row-detail-time">
                {fromName.substring(0, 7)}
              </div>
              {epochToNormalDate(startTime)}
            </div>
            <img
              alt="arrow"
              style={{
                width: "30px",
                height: "26px",
                marginTop: "22px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
              src={arrowImage}
            />
            <div className="order-history-card-row-detail-date">
              {toName}
              <div className="order-history-card-row-detail-time">
                {toName.substring(0, 7)}
              </div>
              {epochToNormalDate(endTime)}
            </div>
          </div>
        </div>
        <div className="order-history-card-behind">
          <div className="order-history-card-behind-display">
            <div className="order-history-card-row-behind">
              <div className="order-history-card-row-detail">
                Име
                <div className="order-history-card-row-text">{forPerson}</div>
              </div>
              <div className="order-history-card-row-detail">
                Имейл
                <div className="order-history-card-row-text">{email}</div>
              </div>
            </div>

            <div className="order-history-card-row-behind">
              <div className="order-history-card-row-detail">
                Сектор
                <div className="order-history-card-row-text">{sektor}</div>
              </div>
              <div className="order-history-card-row-detail">
                Място
                <div className="order-history-card-row-text">{place}</div>
              </div>
            </div>
          </div>
          <div
            className="order-history-card-row-detail-second"
            style={{
              transform: active
                ? `rotate3d(1, 0, 0, -180deg)`
                : `rotate3d(1, 0, 0, 0deg)`,
              transitionDelay: active ? "0.2s" : "0.2s",
            }}
          >
            <div className="order-history-card-row-detail-second-top" />
            <div className="order-history-card-row-detail-second-behind">
              <div className="order-history-card-row-detail-second-display">
                <div className="order-history-card-row-detail-second-display-price">
                  {typeName}
                </div>
                <div className="order-history-card-row-detail-second-display-price">
                  {price.toFixed(2)} лв.
                </div>
              </div>
              <div
                className="order-history-card-row-detail-third"
                style={{
                  transform: active
                    ? `rotate3d(1, 0, 0, -180deg)`
                    : `rotate3d(1, 0, 0, 0deg)`,
                  transitionDelay: active ? "0.4s" : "0s",
                }}
              >
                <div className="order-history-card-row-detail-third-top" />
                <div className="order-history-card-row-detail-second-behind-bottom">
                  <Button
                    type="text"
                    style={{
                      width: "90%",
                      background: "#e6e6e6",
                    }}
                    onClick={() => {
                      dispatch(
                        getTicketPDF(token, companies[0]?.id, ticketNumber)
                      );
                    }}
                    icon={<FilePdfOutlined />}
                    size="large"
                  >
                    Изтегли билет
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderHistory = () => {
  const storedTickets = localStorage.getItem("createdTickets");
  let createdTickets = [];

  if (storedTickets) {
    try {
      createdTickets = JSON.parse(storedTickets);
    } catch (error) {}
  }

  return (
    <>
      <div className="order-history-main-container">
        <div className="order-history-container">
          {createdTickets?.reverse()?.map((ticket, i) => (
            <Cell key={i} tickets={ticket} />
          ))}
        </div>
      </div>
    </>
  );
};
export default OrderHistory;
