import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { LeftOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  displayScheduleDateFullNameFormat,
  epochToNormalDate,
} from "../../utils/utility";
import schedulesActions from "../../redux/schedules/actions";

const BuyTicketHeader = () => {
  const dispatch = useDispatch();
  const { getScheduleStops } = schedulesActions;
  const { schedules, schedulesId, schedulesStopsLoading } = useSelector(
    (state) => state.schedulesReducer
  );
  const { selectedLanguage, token, companies } = useSelector(
    (state) => state.mainReducer
  );

  const { cities } = useSelector((state) => state.citiesReducer);

  const getSchedule = schedules?.find(
    (schedule) => schedule?.kursId === schedulesId
  );

  const handleOpenSchedulesStopsModal = () => {
    dispatch(getScheduleStops(token, companies[0]?.id, getSchedule?.kursId));
    // dispatch(setOpenScheduleInfoModal(true));
  };

  const getCityId = (type) => {
    return schedules?.find((schedule) => schedule?.kursId === schedulesId)?.[
      type
    ];
  };

  const findCityName = (scheduleId) => {
    const name = selectedLanguage === "bg" ? "name" : "nameLat";
    return cities?.find((city) => city?.id === scheduleId)?.[name];
  };

  const displayScheduleTime = (schedule) => {
    return epochToNormalDate(schedule)?.replace(/\d{2}\/\d{2}\/\d{2}/, "");
  };

  return (
    <>
      <div className="buy-ticket-header-container">
        <div className="buy-ticket-header-item buy-ticket-header-item-bg">
          <p className="buy-ticket-header-text remove-pointer-events">{`${findCityName(
            getCityId("fromCity")
          )} - ${findCityName(getCityId("toCity"))}`}</p>
        </div>
        <div className="buy-ticket-header-item buy-ticket-header-item-bg">
          <p className="buy-ticket-header-text remove-pointer-events">
            Тръгване {displayScheduleTime(getSchedule?.scheduleStart)}
          </p>
        </div>
        <div className="buy-ticket-header-item buy-ticket-header-item-bg">
          <p className="buy-ticket-header-text remove-pointer-events">
            Пристигане {displayScheduleTime(getSchedule?.scheduleEnd)}
          </p>
        </div>

        <div className="buy-ticket-header-item buy-ticket-header-item-bg">
          <p className="buy-ticket-header-text remove-pointer-events">
            Сектор {getSchedule?.sector}
          </p>
        </div>
        <div className="buy-ticket-header-item buy-ticket-header-item-bg">
          <p className="buy-ticket-header-text remove-pointer-events">
            {displayScheduleDateFullNameFormat(getSchedule?.scheduleStart)}
          </p>
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          <div className="buy-ticket-header-item buy-ticket-header-back-button">
            <Button
              loading={schedulesStopsLoading}
              style={{ width: "100%" }}
              onClick={handleOpenSchedulesStopsModal}
              size="large"
              icon={<ClockCircleOutlined />}
            >
              Направление
            </Button>
          </div>
          <div className="buy-ticket-header-item buy-ticket-header-back-button">
            <Link to="/schedules">
              <Button
                className="custom-button "
                type="primary"
                size="large"
                icon={<LeftOutlined />}
              >
                <span className="remove-pointer-events">Назад</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyTicketHeader;
