import React, { useState } from "react";
import { useSelector } from "react-redux";

const BuyTicketPriceCard = ({
  setPriceType,
  setPriceId,
  setPriceAmount,
  priceType,
  priceId,
  checkEmptyInput,
  setTicketName,
}) => {
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const { schedules, schedulesId } = useSelector(
    (state) => state.schedulesReducer
  );

  const getExactSchedule = schedules?.find(
    (schedule) => schedule?.kursId === schedulesId
  );

  const selectTicket = (typeId) => {
    if (typeId !== selectedPriceId) {
      setSelectedPriceId(typeId);
    }
  };

  return (
    <>
      <div>
        <p className="buy-ticket-price-card-ticket-heading remove-pointer-events">
          Билет
        </p>
        <div className="buy-ticket-price-card-container">
          {getExactSchedule?.price?.map((price) => {
            return (
              <div
                className={`buy-ticket-price-card ${
                  !priceId &&
                  !priceType &&
                  checkEmptyInput &&
                  "empty-tickets-and-seats"
                } ${
                  selectedPriceId === price.typeId
                    ? "buy-ticket-price-card-selected-ticket"
                    : ""
                }`}
                key={price.typeId}
                onClick={() => {
                  setPriceId(price.id);
                  setPriceType(price.typeId);
                  selectTicket(price.typeId);
                  setPriceAmount(price.price);
                  setTicketName(price.name);
                }}
              >
                <p className="buy-ticket-price-card-heading remove-pointer-events">
                  {price.name}
                </p>

                <p className="buy-ticket-price-card-price remove-pointer-events">
                  {price.price?.toFixed(2)}{" "}
                  <span style={{ fontSize: "15px" }}>лв</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BuyTicketPriceCard;
