import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FloatButton } from "antd";
import ticketsActions from "../../../redux/ticket/actions";
import { useNavigate } from "react-router-dom";
import ticketImage from "../../../images/basket.png";
import { decryptData } from "../../../utils/utility"; // Import the decryption function
import "../index.css";

const TicketCheckoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOpenCheckoutDrawer } = ticketsActions;
  const [localStorageCart, setLocalStorageCart] = useState(() => {
    // Decrypt the data from localStorage
    const cartData = decryptData(localStorage.getItem("cart"));
    return cartData || [];
  });

  useEffect(() => {
    const handleCartUpdate = () => {
      // Decrypt the updated data from localStorage
      const updatedCartData = decryptData(localStorage.getItem("cart"));
      setLocalStorageCart(updatedCartData || []);
    };

    window.addEventListener("cartUpdated", handleCartUpdate);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenCart = () => {
    // Get the current path
    const currentPath = window.location.pathname;
    // Check if the current path already includes '/cart'
    if (currentPath === "/") {
      // If the current path is '/', navigate directly to '/cart'
      navigate("/cart");
    } else if (!currentPath.endsWith("/cart")) {
      // If the current path does not end with '/cart', append '/cart'
      const newPath = `${currentPath}/cart`;
      navigate(newPath);
    }
    dispatch(setOpenCheckoutDrawer(true));
  };

  return (
    <FloatButton
      icon={
        <img
          src={ticketImage}
          className="cart-float-button-image"
          alt="cartIcon"
        />
      }
      className="cart-float-button"
      badge={{
        count: localStorageCart?.length,
      }}
      onClick={handleOpenCart}
    />
  );
};

export default TicketCheckoutButton;
