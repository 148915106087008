import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import ticketsActions from "../../../redux/ticket/actions";
const { Paragraph, Text } = Typography;

const CancelTicketPayment = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.mainReducer);
  const { sendCancelTicketPayment } = ticketsActions;
  const { myPosCompanyId, myPosOrderId } = useParams();

  useEffect(() => {
    if (token.length > 0) {
      dispatch(sendCancelTicketPayment(token, myPosCompanyId, myPosOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    localStorage.removeItem("cart");
    localStorage.removeItem("reservedTickets");
    localStorage.removeItem("ticketExpirationTime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Result
      className="cancel-ticket-payment-result"
      status="error"
      title="Неуспешно плащане"
      extra={[
        <a href="/" key="1">
          <Button
            className="custom-button success-ticket-payment-result-back-button"
            type="primary"
          >
            Начало
          </Button>
        </a>,
      ]}
    >
      <div>
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            Моля, проверете следните възможни причини
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
          Прекратихте плащането - Прекратихтe процеса на плащaне с карта.
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
          Недостатъчна наличност - Проверете дали имате достатъчно средства по
          сметката.
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
          Невалидни данни на картата - Уверете се, че сте въвели правилно всички
          данни на картата, включително номер, срок на валидност и CVV код.
        </Paragraph>

        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
          Технически проблем - Възможно е да има временен технически проблем с
          обработката на плащанията.
        </Paragraph>
      </div>
    </Result>
  );
};

export default CancelTicketPayment;
