import React from "react";
import "./index.css";
const PrivatePolicyPage = () => {
  return (
    <>
      <div className="policy-hero">
        <h3 className="policy-hero-heading remove-pointer-events">
          Политика за поверителност
        </h3>
      </div>
      <div className="policy-container remove-pointer-events">
        <p className="policy-text">
          Тази политика за поверителност описва как Либърти АП ООД събира,
          използва и защитава личните данни на потребителите на нашата система
          за продажба на автобусни билети. Ние се ангажираме да защитаваме
          вашата лична информация и да спазваме Общия регламент за защита на
          данните (GDPR).
        </p>

        <h3 className="policy-heading">1. Събиране на информация</h3>

        <p className="policy-text">
          Ние събираме следната информация:
          <br /> Име <br /> Фамилия <br /> Имейл адрес <br /> Телефонен номер{" "}
          <br /> Платежна информация (само за целите на транзакциите) <br />
          Информация за пътуването (дата, час, дестинация)
        </p>

        <h3 className="policy-heading">2. Правно основание за обработка</h3>

        <p className="policy-text">
          Ние обработваме вашите лични данни на следните основания:
          <br /> Изпълнение на договор <br /> Законово задължение <br />{" "}
          Легитимен интерес <br /> Вашето съгласие
        </p>

        <h3 className="policy-heading">3. Използване на информацията</h3>

        <p className="policy-text">
          Събраната информация се използва за:
          <br /> Обработка на покупки и резервации <br /> Изпращане на
          потвърждения и актуализации
          <br /> Подобряване на нашите услуги <br /> Обслужване на клиенти
        </p>
        <h3 className="policy-heading">4. Споделяне на информацията</h3>

        <p className="policy-text">
          Ние не споделяме личните данни на потребителите с трети страни, освен
          ако това не е необходимо за:
          <br /> Обработка на платежни транзакции <br /> Спазване на законови
          изисквания
          <br /> Защита на нашите права и собственост <br />
        </p>

        <h3 className="policy-heading">5. Защита на информацията </h3>

        <p className="policy-text">
          Ние използваме разнообразни мерки за сигурност, за да гарантираме
          защитата на личните данни, включително:
          <br /> Шифроване на данните <br /> Ограничен достъп до данните
          <br /> Редовни проверки на системите за сигурност <br />
        </p>

        <h3 className="policy-heading">6. Права на потребителите </h3>

        <p className="policy-text">
          Потребителите имат право да:
          <br /> Достъпват личните си данни <br /> Коригират личните си данни
          <br /> Изтриват личните си данни (право да бъдете забравени) <br />
          Ограничат обработката на личните си данни <br /> Преносимост на
          данните
          <br /> Възразят срещу обработката на данните <br /> Откажат
          автоматизирани решения и профилиране
        </p>

        <h3 className="policy-heading">7. Запазване на данни</h3>

        <p className="policy-text">
          Ние съхраняваме личните данни толкова дълго, колкото е необходимо за
          изпълнение на целите, за които са събрани, включително за спазване на
          правни, счетоводни или отчетни изисквания.
        </p>

        <h3 className="policy-heading">8. Трансфер на данни </h3>

        <p className="policy-text">
          Личните ви данни могат да бъдат прехвърлени и съхранявани извън
          Европейското икономическо пространство (ЕИП). В такива случаи ще
          предприемем всички необходими мерки, за да осигурим адекватно ниво на
          защита.
        </p>

        <h3 className="policy-heading">9. Уведомление за нарушение на данни</h3>

        <p className="policy-text">
          В случай на нарушение на сигурността на личните данни, ние ще уведомим
          засегнатите лица и компетентните органи в съответствие с GDPR.
        </p>

        <h3 className="policy-heading">10. Контакт</h3>

        <p className="policy-text">
          Ако имате въпроси или притеснения относно тази политика за
          поверителност, моля, свържете се с нас на: <br /> Имейл:
          libertyap@abv.bg <br /> Телефон: +359887 028 276
        </p>

        <h3 className="policy-heading">
          11. Промени в политиката за поверителност
        </h3>

        <p className="policy-text">
          Ние си запазваме правото да актуализираме тази политика за
          поверителност по всяко време. Промените ще бъдат публикувани на нашия
          уебсайт и ще влязат в сила веднага след тяхното публикуване.
        </p>
      </div>
      <div style={{ height: "50px" }}></div>
    </>
  );
};

export default PrivatePolicyPage;
