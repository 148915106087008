import React from "react";
import { Result } from "antd";
import logo from "../../../images/logo.png";
const EmptyCart = () => {
  return (
    <Result
      icon={<img className="empty-cart-logo" src={logo} alt="emptyCart" />}
      title="Няма добавени билети"
    />
  );
};

export default EmptyCart;
